import { ReactComponent as Sparkle } from "@/assets/svg/Sparkle.svg";
import { Button } from "@/components/ui/button";
import {
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogOverlay,
  DialogTitle,
} from "@/components/ui/dialog";
import { FieldError, Label } from "@/components/ui/field";
import ButtonGroup from "@/components/ui/molecules/ButtonGroup";
import { EditButton } from "@/components/ui/molecules/EditButton";
import {
  Select,
  SelectItem,
  SelectListBox,
  SelectPopover,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { TextArea, TextField } from "@/components/ui/textfield";
import type {
  ArticleSentimentType,
  FeedType,
} from "@/data-access/news/newsFeeds";
import { cn } from "@/lib/utils";
import { estimateLines } from "@/lib/utils/fontMetrics";
import type { OptionType } from "@/types/shared";
import { Smiley, SmileyMeh, SmileySad } from "@phosphor-icons/react";
import { format } from "date-fns";
import { useEffect, useRef, useState } from "react";

const sentimentOptions: OptionType[] = [
  { label: "Positive", value: "positive" },
  { label: "Neutral", value: "neutral" },
  { label: "Negative", value: "negative" },
];

function toTitleCase(str: string) {
  return str
    .toLowerCase()
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}

interface ArticleSentimentPolarityProps extends Partial<FeedType> {
  staleScore?: boolean;
  onSentimentChange: (sentiment: ArticleSentimentType) => void;
}

const ArticleSentimentPolarity: React.FC<ArticleSentimentPolarityProps> = ({
  articleSentiment,
  overriddenArticleSentiment,
  onSentimentChange,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const [attemptedSave, setAttemptedSave] = useState(false);
  const [isEditing, setEditing] = useState(false);
  const currentRationale =
    (overriddenArticleSentiment?.rationale || articleSentiment?.rationale) ??
    "";
  const currentPolarity =
    (
      overriddenArticleSentiment?.polarity || articleSentiment?.polarity
    )?.toLowerCase() ?? "";
  const [unsavedPolarity, setUnsavedPolarity] = useState(currentPolarity);
  const [rationale, setRationale] = useState(currentRationale);
  const [polarity, setPolarity] = useState(currentPolarity);
  const [errorMessage, setErrorMessage] = useState("");
  const polarityMarkTime = overriddenArticleSentiment?.intensity ?? null;
  const [polarityMark, setPolarityMark] = useState(
    polarityMarkTime
      ? `Edited on ${format(polarityMarkTime, "MM/dd/yyyy")}`
      : "",
  );
  const [isAISentiment, setIsAISentiment] = useState(
    polarity === articleSentiment?.polarity,
  );

  const getSentimentIcon = (polarity: string | undefined) => {
    switch (polarity) {
      case "positive":
        return <Smiley size={24} />;
      case "negative":
        return <SmileySad size={24} />;
      default:
        return <SmileyMeh size={24} />;
    }
  };

  const handleEditClick = () => {
    setEditing(true);
  };

  const onUpdateSentimentPolarity = () => {
    if (polarity && rationale) {
      onSentimentChange({
        polarity,
        rationale,
        intensity: new Date().getTime(),
      });
      setUnsavedPolarity(polarity);
      setEditing(false);
    } else {
      setAttemptedSave(true);
      setErrorMessage("Please provide a rationale");
    }
  };

  const onUndo = () => {
    setPolarity(articleSentiment?.polarity || "");
    setRationale(articleSentiment?.rationale || "");
  };

  useEffect(() => {
    setRationale("");
    setTimeout(() => {
      rationaleRef.current?.focus();
    }, 0);
    setAttemptedSave(false);
    setErrorMessage("");
    const isAISentiment = polarity === articleSentiment?.polarity;
    setIsAISentiment(isAISentiment);
    if (isAISentiment) setRationale(articleSentiment.rationale);
    setPolarityMark(
      !isAISentiment
        ? `Manually edited from ${toTitleCase(
            articleSentiment?.polarity || "",
          )} to ${toTitleCase(polarity)} on ${format(new Date(), "MM/dd/yyyy")}`
        : "",
    );
  }, [polarity]);

  useEffect(() => {
    if (isAISentiment) return;
    setErrorMessage(
      attemptedSave && rationale.trim().length === 0
        ? "Please provide a rationale."
        : "",
    );
  }, [rationale]);

  const TEXT_AREA_WIDTH_PX = 715;
  const ESTIMATED_CHAR_WIDTH_PX = 6;
  const PADDING_LINES = 2;

  const onCancel = () => {
    setPolarity(unsavedPolarity);
    setRationale(currentRationale);
    setEditing(false);
  };

  const rationaleRef = useRef<HTMLTextAreaElement>(null);

  const textAreaLineCount =
    estimateLines(rationale, ESTIMATED_CHAR_WIDTH_PX, TEXT_AREA_WIDTH_PX) +
    PADDING_LINES;

  return (
    <div
      className={cn(
        "group rounded",
        isHovered && "bg-slate-200/20",
        "p-1 -m-1 mr-1 relative flex flex-row space-x-1 items-center text-sm text-gray-700/90 font-medium",
        unsavedPolarity === "positive" && "text-green-700",
        unsavedPolarity === "negative" && "text-rose-700/90",
      )}
    >
      {unsavedPolarity ? (
        <>
          {getSentimentIcon(unsavedPolarity)}
          <span className="first-letter:uppercase font-semibold">
            {unsavedPolarity}
          </span>
        </>
      ) : (
        <>-</>
      )}
      <EditButton
        onEditClick={handleEditClick}
        onHoverChange={setIsHovered}
        className="right-1"
      />
      <DialogOverlay
        isOpen={isEditing}
        onOpenChange={setEditing}
        shouldCloseOnInteractOutside={() => unsavedPolarity === polarity}
      >
        <DialogContent closeButton={true} onClose={onCancel}>
          <DialogHeader>
            <DialogTitle>Edit Article Sentiment</DialogTitle>
          </DialogHeader>
          <Select
            className="max-w-48"
            selectedKey={polarity}
            onSelectionChange={(key) => setPolarity(key as string)}
          >
            {isAISentiment ? (
              <Label className="items-center gap-1">
                <span>AI Sentiment</span>
                <Sparkle />
              </Label>
            ) : (
              <Label>
                Sentiment<span className="text-gray-500 font-normal">*</span>
              </Label>
            )}
            <SelectTrigger>
              <SelectValue className="first-letter:uppercase">
                {polarity}
              </SelectValue>
            </SelectTrigger>
            <SelectPopover>
              <SelectListBox>
                {sentimentOptions.map((option) => (
                  <SelectItem
                    key={option.value}
                    id={option.value}
                    textValue={option.label}
                  >
                    <span>{option.label}</span>
                    {option.value === articleSentiment?.polarity && <Sparkle />}
                  </SelectItem>
                ))}
              </SelectListBox>
            </SelectPopover>
          </Select>
          <TextField
            className="group flex flex-col relative"
            value={rationale}
            onChange={setRationale}
            isRequired
            name="rationale"
            type="text"
          >
            <Label>
              {isAISentiment && "AI"} Rationale
              {!isAISentiment && (
                <span className="text-gray-500 font-normal">*</span>
              )}
            </Label>

            {/* Step 3: Bind the ref to the TextArea */}
            <TextArea
              ref={rationaleRef} // Bind the ref here
              disabled={isAISentiment}
              rows={textAreaLineCount}
              className={!isAISentiment ? "pb-8" : ""}
              placeholder="Add a rationale..."
            />

            {!isAISentiment && (
              <span
                className={cn(
                  "absolute text-[10px] left-2 text-gray-200",
                  errorMessage ? "bottom-9" : "bottom-2",
                )}
              >
                {polarityMark}
              </span>
            )}

            <FieldError>{errorMessage}</FieldError>
          </TextField>
          <DialogFooter className="sm:justify-between">
            {isAISentiment ? (
              <div />
            ) : (
              <Button variant="ghost" onPress={onUndo} className="pl-0">
                Revert to AI Sentiment
                <Sparkle className="ml-2" />
              </Button>
            )}
            <ButtonGroup
              primaryAction={onUpdateSentimentPolarity}
              primaryLabel="Save"
              secondaryAction={onCancel}
              secondaryLabel="Cancel"
            />
          </DialogFooter>
        </DialogContent>
      </DialogOverlay>
    </div>
  );
};

export default ArticleSentimentPolarity;
