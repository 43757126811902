import { graphql } from "../graphql";

export const CategoryQuery = graphql(`
  query CategoryQuery {
    categories
  }
`);

export type CategoryQueryResponse = ReturnType<
  Exclude<typeof CategoryQuery.__apiType, undefined>
>;
