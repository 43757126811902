import { dataServiceBaseUrl } from "@/data-access/core/constants";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const emptySplitApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: `${dataServiceBaseUrl}/`,
    fetchFn: async (...args) => {
      const { standardAuthFetch } = await import(
        "@/data-access/core/authenticatedFetch"
      );
      return standardAuthFetch(...args);
    },
  }),
  endpoints: () => ({}),
});
