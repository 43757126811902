import { Button } from "@/components/ui/controls/button"; // Ensure you have this import for the Button component
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/data-display/tooltip";
import { Checkbox } from "@/components/ui/forms/checkbox";
import { cn } from "@/lib/utils";
import { Check, Trash } from "@phosphor-icons/react";
import {
  type FC,
  type HTMLAttributes,
  memo,
  useEffect,
  useRef,
  useState,
} from "react";

interface MemoizedTooltipProps {
  filterValue: string;
  labelRef: React.RefObject<HTMLLabelElement>;
  handleEditClick?: (tier: string) => Promise<void>;
  canEdit: boolean;
  isHovered: boolean;
  tabIndex: number;
  setIsEditing: React.Dispatch<React.SetStateAction<boolean>>;
  title: string;
}

// A memoized Tooltip component to avoid unnecessary re-renders
const MemoizedTooltip: React.FC<MemoizedTooltipProps> = memo(
  ({
    filterValue,
    labelRef,
    handleEditClick,
    canEdit,
    isHovered,
    tabIndex,
    setIsEditing,
    title,
  }) => (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild>
          <label
            ref={labelRef}
            htmlFor={filterValue}
            className="relative w-full capitalize text-sm font-normal text-slate-700 overflow-hidden whitespace-nowrap overflow-ellipsis pr-2.5"
          >
            <span className="text-slate-700">{filterValue}</span>
            {canEdit && isHovered && (
              <Button
                className={cn(
                  "ml-2 h-5 px-1 py-0 text-xs text-slate-700 bg-white border-none shadow-sm hover:bg-white-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500",
                  canEdit && "absolute right-0",
                )}
                onClick={(e) => {
                  e.stopPropagation();
                  setIsEditing(true);
                }}
                tabIndex={tabIndex}
              >
                Edit
              </Button>
            )}
            {handleEditClick && isHovered && (
              <button
                type="button"
                onClick={(e) => {
                  e.stopPropagation();
                  handleEditClick(title);
                }}
                tabIndex={tabIndex}
                className="bg-white text-sm font-normal text-slate-700 ml-2 py-0 px-2 rounded-md"
              >
                Edit
              </button>
            )}
          </label>
        </TooltipTrigger>
        <TooltipContent>
          <p title={filterValue}>{filterValue}</p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  ),
);

export interface FilterItemProps {
  title: string;
  count: number;
  selected: boolean;
  tabIndex: number;
  toggleFilter: () => void;
  handleEditClick?: (tier: string) => Promise<void>;
  confirmValue?: (updatedValue: string) => void;
  confirmRemove?: () => void;
}

const FilterComponentItem: FC<FilterItemProps & HTMLAttributes<HTMLElement>> =
  ({
    title,
    count,
    selected,
    tabIndex,
    toggleFilter,
    handleEditClick,
    confirmValue,
    confirmRemove,
  }) => {
    const [isHovered, setIsHovered] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [filterValue, setFilterValue] = useState(title);
    const [confirmedValue, setConfirmedValue] = useState(title);
    const inputRef = useRef<HTMLInputElement | null>(null);
    const labelRef = useRef<HTMLLabelElement>(null);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setConfirmedValue(e.target.value);
    };

    const removeTopTopic = () => {
      setIsEditing(false);
      if (!confirmRemove) return;
      confirmRemove();
    };

    useEffect(() => {
      if (isEditing && inputRef.current) {
        setTimeout(() => {
          inputRef.current?.focus();
        }, 300);
      }
    }, [isEditing]);

    const handleConfirmEditing = (
      e: React.MouseEvent<SVGSVGElement, MouseEvent>,
    ) => {
      setIsEditing(false);
      e.preventDefault();
      e.stopPropagation();
      setFilterValue(confirmedValue);

      if (!confirmValue) return;
      confirmValue(confirmedValue);
    };

    const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === "Enter") {
        e.preventDefault();
        e.stopPropagation();
        setIsEditing(false);
        setFilterValue(confirmedValue);
        if (!confirmValue) return;
        confirmValue(confirmedValue);
      } else if (e.key === "Escape") {
        e.preventDefault();
        e.stopPropagation();
        setIsEditing(false);
      }
    };

    const handleCheckboxChange = () => {
      toggleFilter();
    };

    const canEdit = Boolean(confirmValue) && Boolean(confirmRemove);

    return (
      <div
        key={filterValue}
        className="justify-between flex items-center hover:bg-slate-300/10 rounded-md cursor-pointer px-2 py-1"
        onClick={handleCheckboxChange}
        onKeyDown={(event) => {
          if (event.key === "Enter" || event.key === "Space") {
            handleCheckboxChange();
          }
        }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        role="button"
      >
        <div className="flex items-center flex-grow space-x-2">
          <Checkbox
            checked={selected}
            tabIndex={tabIndex}
            onChange={handleCheckboxChange}
            variant={"secondary"}
          />
          {!isEditing && (
            <MemoizedTooltip
              filterValue={filterValue}
              labelRef={labelRef}
              handleEditClick={handleEditClick}
              canEdit={canEdit}
              isHovered={isHovered}
              tabIndex={tabIndex}
              setIsEditing={setIsEditing}
              title={title}
            />
          )}
          {isEditing && (
            <div className="flex relative">
              <input
                type="text"
                className="form-control text-sm border rounded-md px-2 pr-5 py-1 border-slate-200 text-slate-700 overflow-hidden whitespace-nowrap overflow-ellipsis focus-visible:outline-none focus-visible:ring-1"
                value={confirmedValue}
                onChange={handleInputChange}
                onKeyDown={handleKeyPress}
                onClick={(e) => e.stopPropagation()}
                ref={inputRef}
              />
              <Check
                size={16}
                className="text-slate-600 absolute right-1 top-1.5 z-10 cursor-pointer"
                onClick={handleConfirmEditing}
              />
            </div>
          )}
        </div>
        {isEditing ? (
          <Trash
            size={16}
            className="text-slate-600 ml-2"
            onClick={(e) => {
              e.stopPropagation();
              removeTopTopic();
            }}
          />
        ) : (
          <div
            className={cn(
              "text-sm min-w-[1em] text-right ml-2",
              count ? "text-slate-600" : "text-slate-600",
            )}
          >
            {count}
          </div>
        )}
      </div>
    );
  };

export default FilterComponentItem;
