import HeaderLayout from "@/components/layouts/HeaderLayout";
import { Button } from "@/components/ui/button";
import { Badge } from "@/components/ui/data-display/badge";
import { Skeleton } from "@/components/ui/feedback/skeleton";
import { useFeature } from "@/lib/feature-management";
import useFeedPanelStore from "@/store/feedPanelSlice";
import useFeedStore, {
  useArticleCount,
  useFeedName,
} from "@/store/useFeedStore";
import { Gear, Plus } from "@phosphor-icons/react";
import { FilterDays } from "../shared/FilterDays";
import FeedAddDropdown from "./FeedAddDropdown";

const FeedHeader = () => {
  const [openEditPanel, openAddPanel] = useFeedPanelStore((state) => [
    state.openEditPanel,
    state.openAddPanel,
  ]);
  const [feedId, filterDays, updateDateRange, loadingFeed] = useFeedStore(
    (state) => [
      state.feedId,
      state.filterDays,
      state.updateDateRange,
      state.fetchingFeed,
    ],
  );

  const canAddArticle = useFeature("add-article", false);
  const showStoryArticleCounts = useFeature("show-story-article-counts", false);
  const feedName = useFeedName();
  const articleCount = useArticleCount();

  if (!feedId) {
    return (
      <header className="sticky top-0 bg-white">
        <HeaderLayout>
          <div className="flex items-center">
            <h1 className="text-2xl font-serif text-text-primary mr-3">
              All Newsfeeds
            </h1>
            <FilterDays
              filterDays={filterDays.toString()}
              updateDateRange={updateDateRange}
            />
          </div>
          <Button onPress={openAddPanel}>
            <Plus className="mr-2 size-4" weight="bold" /> New Newsfeed
          </Button>
        </HeaderLayout>
      </header>
    );
  }

  return (
    <header className="sticky top-0 bg-white">
      <HeaderLayout>
        <div className="flex flex-row flex-grow">
          <h1 className="text-2xl font-serif text-text-primary mr-4 max-w-[60%] overflow-hidden text-ellipsis line-clamp-1">
            {feedName || "News"}
          </h1>
          {showStoryArticleCounts && (
            <div className="flex items-center gap-2">
              {!loadingFeed ? (
                <Badge status={false}>
                  {articleCount}
                  {articleCount !== 1 ? " Articles" : " Article"}
                </Badge>
              ) : (
                <Skeleton className="h-6 w-20" />
              )}
            </div>
          )}
        </div>
        <div className="flex flex-row-reverse items-center gap-2">
          <Button variant="outline" onPress={openEditPanel}>
            <Gear className="w-6 h-6 text-slate-700" />
          </Button>
          {canAddArticle && <FeedAddDropdown />}
        </div>
      </HeaderLayout>
    </header>
  );
};

export default FeedHeader;
