import { Separator } from "@/components/ui/controls/separator";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/data-display/tooltip";

import { ReactComponent as Sparkle } from "@/assets/svg/Sparkle.svg";
import { Skeleton } from "@/components/ui/feedback/skeleton";
import type {
  ArticleSentimentType,
  FeedType,
} from "@/data-access/news/newsFeeds";
import { cn } from "@/lib/utils";
import { useFeedName } from "@/store/useFeedStore";
import {
  Gauge,
  Info,
  Smiley,
  SmileyMeh,
  SmileySad,
} from "@phosphor-icons/react";
import type React from "react";
import { useState } from "react";
import ArticleSentimentPolarity from "./ArticleSentimentPolarity";

interface ScoreDisplayProps extends Partial<FeedType> {
  staleScore?: boolean;
  onSentimentChange: (sentiment: ArticleSentimentType) => void;
}

const ScoreDisplay: React.FC<ScoreDisplayProps> = ({
  maxScore,
  articleSentiment,
  overriddenArticleSentiment,
  staleScore = false,
  onSentimentChange,
}) => {
  const polarity = (
    overriddenArticleSentiment?.polarity || articleSentiment?.polarity
  )?.toLowerCase();
  const [rationale, setRationale] = useState(
    overriddenArticleSentiment?.rationale || articleSentiment?.rationale,
  );
  const [isAISentiment, setIsAISentiment] = useState(
    (!overriddenArticleSentiment?.polarity && articleSentiment?.polarity) ||
      overriddenArticleSentiment?.polarity.toLowerCase() ===
        articleSentiment?.polarity,
  );
  const feedName = useFeedName();
  const getSentimentIcon = (polarity: string | undefined) => {
    switch (polarity) {
      case "positive":
        return <Smiley className="w-6 h-6" />;
      case "negative":
        return <SmileySad className="w-6 h-6" />;
      default:
        return <SmileyMeh className="w-6 h-6" />;
    }
  };
  return (
    <TooltipProvider>
      <div className="flex flex-col gap-2.5 pt-7 w-30">
        <div className="-mb-1">
          <div className="text-gray-900 text-xs leading-7 font-semibold">
            Impact Score
          </div>
          <div className="inline-flex space-x-1 text-violet-800 items-center">
            <Gauge className="w-6 h-6" />
            {staleScore ? (
              <Skeleton className="flex justify-center bg-fuschia-600 items-center w-11 h-11" />
            ) : (
              <p className="font-semibold text-base text-center">{maxScore}</p>
            )}
          </div>
        </div>

        <Separator />
        <div>
          <div className="flex flex-row space-x-1 items-center">
            <div
              className="text-gray-900 text-xs leading-7 font-semibold"
              title={rationale}
            >
              {isAISentiment ? <>AI Sentiment</> : <>Sentiment</>}
            </div>
            {isAISentiment && <Sparkle />}
            {polarity && (
              <Tooltip>
                <TooltipTrigger>
                  <Info className="text-slate-600" />
                </TooltipTrigger>
                <TooltipContent
                  side="bottom"
                  align="end"
                  className="z-50 py-4 max-w-md"
                >
                  <div className="flex flex-row space-x-1 items-center text-slate-700/90 mb-1">
                    <span
                      className={cn(
                        polarity === "positive" && "text-green-800",
                        polarity === "negative" && "text-rose-700/90",
                      )}
                    >
                      {getSentimentIcon(polarity)}
                    </span>
                    <span className="font-bold text-sm whitespace-nowrap">
                      {`Why is this article ${polarity} for
                      ${feedName}?`}
                    </span>
                  </div>
                  <p className="ml-6 font-normal text-xs whitespace-normal break-words">
                    {rationale}
                  </p>
                </TooltipContent>
              </Tooltip>
            )}
          </div>
          <ArticleSentimentPolarity
            articleSentiment={articleSentiment}
            overriddenArticleSentiment={overriddenArticleSentiment}
            onSentimentChange={(sentiment) => {
              setRationale(sentiment?.rationale);
              setIsAISentiment(
                sentiment?.polarity === articleSentiment?.polarity,
              );
              onSentimentChange(sentiment);
            }}
          />
        </div>
      </div>
    </TooltipProvider>
  );
};

export default ScoreDisplay;
