import type { createFormSchema } from "@/components/campaigns/createFormSchema";
import { toast } from "@/components/ui/toast";
import {
  useArticlesAmplifyRetrieveQuery,
  useArticlesSearchRetrieveQuery,
} from "@/store/generated-apis/articleApi";
import {
  type Ad,
  type Campaign,
  useCampaignsAdsCreateMutation,
  useCampaignsCreateMutation,
} from "@/store/generated-apis/campaignApi";
import type { SerializedError } from "@reduxjs/toolkit";
import { type FetchBaseQueryError, QueryStatus } from "@reduxjs/toolkit/query";
import { useEffect } from "react";
import type { UseFormReturn } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import type { z } from "zod";

function useCampaignCreationEffects({
  form,
  articleUrl,
  submitted,
}: {
  form: UseFormReturn<z.infer<typeof createFormSchema>>;
  articleUrl: string;
  submitted: boolean;
}): [startCreation: (campaign: Campaign) => void, isLoadingCreate: boolean] {
  const navigate = useNavigate();

  const [
    createCampaign,
    { data: campaignData, error: campaignError, isLoading: isLoadingCampaign },
  ] = useCampaignsCreateMutation();

  const [
    createCampaignAd,
    {
      error: adError,
      data: campaignAdData,
      status: createCampaignAdStatus,
      isLoading: isLoadingAd,
    },
  ] = useCampaignsAdsCreateMutation();

  const {
    data: articleData,
    error: articleError,
    isLoading: isLoadingArticle,
  } = useArticlesSearchRetrieveQuery(
    { url: articleUrl },
    { skip: !articleUrl },
  );
  useArticlesAmplifyRetrieveQuery(
    { id: +(articleData?.id || 0) },
    { skip: !articleData?.id },
  );

  const isLoadingCreate =
    submitted && (isLoadingCampaign || isLoadingAd || isLoadingArticle);

  const trimUrlOnSubmit = () => {
    const url = form.getValues("url");
    if (url && submitted) {
      form.setValue("url", url.trim());
    }
  };

  const createAdOnDataReceived = (article: number, campaign: number) => {
    const ad: Ad = {
      article,
      campaign,
      utm_url: form.getValues("url"),
      image: articleData?.images?.[0]?.id,
    };
    createCampaignAd({ ad });
  };

  const handleError = (
    error: FetchBaseQueryError | SerializedError | undefined,
  ) => {
    if (!error) return;

    let errorMessage = "data" in error && error.data;
    if (!errorMessage) {
      errorMessage = "message" in error ? error.message : "Unknown error";
    }
    if (!errorMessage) {
      console.error("Error:", error);
      errorMessage = "There was an error please try again.";
    }
    if (typeof errorMessage !== "string") return;
    toast.error(errorMessage);
  };

  useEffect(trimUrlOnSubmit, [form, submitted]);

  useEffect(() => {
    if (campaignData && articleData) {
      createAdOnDataReceived(articleData.id, campaignData.id);
    } else if (campaignData) {
      navigate(`/campaigns/${campaignData.id}/edit`);
    } else {
      return;
    }
  }, [createCampaignAd, campaignData, articleData, form]);

  useEffect(() => {
    if (createCampaignAdStatus !== QueryStatus.fulfilled || !campaignAdData)
      return;
    navigate(`/campaigns/${campaignAdData.campaign}/edit`);
  }, [navigate, createCampaignAdStatus, campaignAdData]);

  useEffect(() => handleError(campaignError), [campaignError]);
  useEffect(() => handleError(adError), [adError]);
  useEffect(() => handleError(articleError), [form, articleError]);

  const startCreation = (campaign: Campaign) => {
    createCampaign({ campaign });
  };

  return [startCreation, isLoadingCreate];
}

export default useCampaignCreationEffects;
