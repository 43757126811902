import { Button } from "@/components/ui/button";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/data-display/tooltip";
import { useDownloadFile } from "@/data-access/core/useDownloadFile";
import useAuth from "@/store/authSlice";
import useFeedStore, { useFeedName } from "@/store/useFeedStore";
import { Export } from "@phosphor-icons/react";

export const FeedExport = () => {
  const [feedId, filterDays] = useFeedStore((state) => [
    state.feedId,
    state.filterDays,
  ]);

  const feedName = useFeedName();

  const user = useAuth((state) => state.user);
  const tenant = user?.tenants?.[0]?.slug;

  const { ref, fileUrl, download, fileName } = useDownloadFile({
    onError: (e) => console.error(e),
    getFileName: () => `${feedName}-${new Date().toISOString()}-export.csv`,
  });

  const downloadFile = () => {
    const endpoint = `/articles/feed/${feedId}/export/?daysFilter=${filterDays}`;
    download(endpoint, tenant);
  };

  return (
    <div className="flex ml-1 justify-end">
      <a href={fileUrl} download={fileName} className="hidden" ref={ref}>
        download
      </a>
      {/* <Button
        size="sm"
        variant="outline"
        role="combobox"
        className="justify-between font-normal bg-white border border-slate-200"
        leftIcon={<DownloadSimple />}
        onClick={() => downloadFile()}
      /> */}

      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger asChild>
            <Button
              variant="outline"
              size="icon"
              aria-label="Export"
              onPress={() => downloadFile()}
            >
              <Export className="w-5 h-5 text-slate-600" />
            </Button>
          </TooltipTrigger>
          <TooltipContent>Export Feed</TooltipContent>
        </Tooltip>
      </TooltipProvider>
    </div>
  );
};
