import { cn } from "@/lib/utils";

interface FeedContentLayoutProps {
  children: [React.ReactNode, React.ReactNode];
}

export const FeedContentLayout: React.FC<FeedContentLayoutProps> = ({
  children,
}) => {
  const [header, content] = children;

  return (
    <div
      className={cn(
        "h-screen grid grid-rows-[auto_1fr] overflow-hidden",
        "relative",
      )}
    >
      {header}
      {content}
    </div>
  );
};
