import { useFeature } from "@/lib/feature-management";
import { FeaturesReady } from "@growthbook/growthbook-react";
import {
  type ReactNode,
  Suspense,
  createElement,
  lazy,
  memo,
  useMemo,
} from "react";
import { useDispatch } from "react-redux";
import {
  Navigate,
  Outlet,
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";

import { CustomLoader } from "@/shared/CustomLoader";
import { selectCampaign } from "@/store/actions/builder";

import CustomAdsBlockerDialog from "./components/common/CustomAdsBlockerDialog";

import MainLayout from "./components/layouts/MainLayout";
import SettingsLayout from "./components/layouts/SettingsLayout";
import ArticlePreview from "./modules/article-preview/ArticlePreview";
import NewsFeedsPage from "./pages/NewsFeedsPage";
import useAuthRoutes from "./pages/auth/useAuthRoutes";
import NewsFeedsScreen from "./pages/newsfeeds/FeedPage";
import SettingsPage from "./pages/settings/SettingsScreen";
import StoryPage from "./pages/stories/StoryPage";
import { LazyLoader } from "./shared/LazyLoader";
import useAuth from "./store/authSlice";
import useFeedStore from "./store/useFeedStore";

const CampaignEdit = lazy(() =>
  import("./pages/campaigns").then((m) => ({
    default: m.CampaignEdit,
  })),
);

const CampaignsPage = lazy(() =>
  import("./pages/campaigns").then((m) => ({
    default: m.CampaignsPage,
  })),
);

export default memo(function AppRouter() {
  const campaignsEnabled = useFeature("campaigns-enabled", false);
  const loggedIn = useAuth((state) => state.loggedIn);
  // biome-ignore lint/suspicious/noExplicitAny: <using outdated dispatch that is not setup correctly, any is required>
  const dispatch: any = useDispatch();
  const authRoutes = useAuthRoutes();
  const setFeedId = useFeedStore((state) => state.setFeedId);

  const getProtectedElement = useMemo(
    () =>
      (
        element: React.FC<{ children: ReactNode[] | ReactNode }>,
        redirectTo = "/auth/login",
      ) => {
        return loggedIn ? (
          // biome-ignore lint/correctness/noChildrenProp: <react router dom requires this>
          createElement(element, { children: <Outlet /> })
        ) : (
          <Navigate to={redirectTo} />
        );
      },
    [loggedIn],
  );

  const router = useMemo(
    () =>
      createBrowserRouter([
        authRoutes,
        {
          path: "/article-preview/:id",
          Component: ArticlePreview,
        },
        {
          path: "/",
          element: getProtectedElement(MainLayout),
          children: [
            {
              path: "",
              element: <Navigate to="/newsfeeds" />,
            },
            {
              path: "newsfeeds",
              children: [
                {
                  path: "",
                  element: <NewsFeedsPage />,
                  loader: async () => {
                    setFeedId(null);
                    return null;
                  },
                },
                {
                  path: ":feedId",
                  loader: async ({ params }) => {
                    const feedId = params.feedId;
                    if (!feedId)
                      throw new Response("No feed id provided", {
                        status: 404,
                      });
                    setFeedId(+feedId);
                    return null;
                  },
                  children: [
                    {
                      path: "articles/:articleId",
                      element: <NewsFeedsScreen />,
                    },
                    {
                      path: "story/:storyId/*",
                      children: [
                        {
                          path: "articles/:articleId",
                          element: <StoryPage />,
                        },
                        {
                          path: "*",
                          element: <StoryPage />,
                        },
                      ],
                    },
                    {
                      path: "",
                      element: <NewsFeedsScreen />,
                    },
                  ],
                },
              ],
            },
            campaignsEnabled
              ? {
                  path: "campaigns",
                  children: [
                    {
                      path: "",
                      Component: CampaignsPage,
                    },
                    {
                      path: ":campaignId",
                      loader: async ({ params: { campaignId } }) => {
                        if (!campaignId) {
                          throw new Response("No campaign id provided", {
                            status: 400,
                          });
                        }
                        return await dispatch(
                          selectCampaign(campaignId ? +campaignId : 0),
                        );
                      },
                      element: <Outlet />,
                      children: [
                        {
                          path: "",
                          element: <Navigate to="edit" replace />,
                        },
                        {
                          path: "edit",
                          Component: CampaignEdit,
                        },
                      ],
                    },
                  ],
                }
              : {},
            {
              path: "*",
              element: <Navigate to="/newsfeeds" />,
            },
          ],
        },
        {
          path: "/settings",
          element: getProtectedElement(SettingsLayout),
          children: [
            {
              path: "",
              Component: SettingsPage,
            },
          ],
        },
      ]),
    [authRoutes, campaignsEnabled, getProtectedElement, dispatch, setFeedId],
  );

  return (
    <Suspense fallback={<LazyLoader delay={500} />}>
      <FeaturesReady timeout={500} fallback={<CustomLoader />}>
        <CustomAdsBlockerDialog />
        <RouterProvider router={router} />
      </FeaturesReady>
    </Suspense>
  );
});
