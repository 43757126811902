import { type StateCreator } from "zustand";

export type PublishersSlice = {
  selectedTab: string;
  displayPanel: boolean;
  setSelectedTab: (tab: string) => void;
  setDisplayPanel: (displayPanel: boolean) => void;
};

export const createPublishersSlice: StateCreator<PublishersSlice> = (
  set,
  _,
) => {
  return {
    selectedTab: "tier1",
    displayPanel: false,
    setSelectedTab: (tab) => set({ selectedTab: tab }),
    setDisplayPanel: (displayPanel) => set({ displayPanel }),
  };
};
