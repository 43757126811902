import { Button } from "@/components/ui/button";
import { useAppDispatch, useAppSelector } from "@/lib/hooks/storeHooks";
import { cn } from "@/lib/utils";
import { setLeftSidebarVisible } from "@/store/actions/builder";
import { SidebarSimple } from "@phosphor-icons/react";
import React from "react";

interface HeaderLayoutProps {
  children: React.ReactNode;
}
const HeaderLayout: React.FC<HeaderLayoutProps> = ({ children }) => {
  const childrenArray = React.Children.toArray(children);

  const dispatch = useAppDispatch();
  const { isSidebarVisible } = useAppSelector((state) => state.builder);

  if (childrenArray.length !== 2) {
    throw new Error("HeaderLayout expects exactly two children");
  }
  const [leftContent, rightContent] = childrenArray;
  return (
    <div className="flex flex-wrap justify-between items-center px-7 py-5 border-b border-slate-100">
      {!isSidebarVisible && (
        <Button
          onPress={() => dispatch(setLeftSidebarVisible(true))}
          variant="link"
          className={"absolute left-2 px-2 top-[25px] z-10 h-auto"}
        >
          <SidebarSimple />
        </Button>
      )}
      <div
        className={cn(
          "flex items-center flex-grow",
          !isSidebarVisible && "pl-3",
        )}
      >
        {leftContent}
      </div>
      <div className="flex items-center flex-grow justify-end">
        {rightContent}
      </div>
    </div>
  );
};

export default HeaderLayout;
