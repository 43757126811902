import Card from "@/components/ui/card";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/navigation/popover";
import type { FeedWithAugTypes } from "@/data-access/news/newsFeeds";
import { DotsThree, Trash } from "@phosphor-icons/react";
import { useState } from "react";

import { hasSecondSection } from "@/components/news/shared/utils";

import PlaceHolderImage from "@/assets/images/article-placeholder.webp";
import { Button } from "@/components/ui/button";
import { Separator } from "@/components/ui/controls/separator";
import type { CategoryQueryResponse } from "@/data-access/news/categoryQuery";
import { cn } from "@/lib/utils";
import type {
  ArticleCategoryUpdateProp,
  ArticleEditFn,
  AuthorType,
} from "@/types/article";
import ArticleImage from "../feed/NewsImage";
import ScoreDisplay from "../feed/ScoreDisplay";
import ScoreDetail from "../feed/score/ScoreDetail";
import { ArticleBadgeRow } from "../shared/ArticleBadgeDisplay";
import {
  calculateTopHeight,
  getArticleHeight,
} from "../shared/getArticleHeight";
import ArticleMentionText from "./ArticleMentionText";
import ArticleAuthor from "./FeedArticleAuthor";
import ArticleHeader from "./FeedArticleHeader";
import ArticleSummary from "./FeedArticleSummary";

export type FeedArticleProps = {
  categories: CategoryQueryResponse["categories"];
  article: Partial<FeedWithAugTypes>;
  handleClick?: () => void;
  onChange?: ArticleEditFn;
  handleDeleteClick?: () => void;
  image?: HTMLImageElement;
  staleScore?: boolean;
};

const FeedArticle = ({
  categories,
  article,
  handleClick,
  handleDeleteClick,
  onChange,
  image,
  staleScore = true,
}: FeedArticleProps) => {
  const [isHovered, setIsHovered] = useState(false);
  const [summary, setSummary] = useState(
    article.overriddenSummary || article.summary || "Add a summary...",
  );
  const onChangeSummary = (summary: string) => {
    setSummary(summary);
    if (onChange) onChange({ articleId: article.id ?? 0, summary });
  };

  const articleHeight = getArticleHeight({ ...article, summary });
  const topSectionHeight = calculateTopHeight(article);

  const secondSection = hasSecondSection(article);

  const gridTemplateRows = secondSection
    ? `${topSectionHeight}px auto`
    : `${topSectionHeight}px`;

  return (
    <Card
      className={cn(
        "p-5 text-left md:max-w-[900px] w-full relative grid grid-cols-[auto,1fr,1px,119px] gap-3.5 pb-2 overflow-visible",
      )}
      style={{
        height: articleHeight,
        gridTemplateRows,
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {article.imageUrl && (
        <ArticleImage
          size="medium"
          image={image}
          imageUrl={article.imageUrl}
          isHovering={isHovered}
        />
      )}
      {!article.imageUrl && (
        <ArticleImage
          size="medium"
          imageUrl={PlaceHolderImage}
          isHovering={isHovered}
        />
      )}
      <div className={cn("flex flex-col gap-y-2")}>
        <ArticleHeader
          articleId={article.id ?? 0}
          headline={article.headline ?? ""}
          overriddenHeadline={
            article.overriddenHeadline
              ? (article.overriddenHeadline as string)
              : undefined
          }
          articlePublisher={article.articlePublisher ?? null}
          articleUrl={article.articleUrl}
          handleHeadlineClick={handleClick}
          lastUpdate={article.articleLastUpdateDate}
          onHeadlineChange={(headline) => {
            if (onChange) onChange({ articleId: article.id ?? 0, headline });
          }}
        />
        <ArticleAuthor
          articleAuthors={article.articleAuthors || []}
          overriddenArticleAuthors={
            (article.overriddenArticleAuthors as AuthorType[]) ?? undefined
          }
          onAuthorChange={(author) => {
            if (onChange)
              onChange({ articleId: article.id ?? 0, author: author || "" });
          }}
        />
        <ScoreDetail
          articleId={article.articleId}
          maxDomainAuthority={article.maxDomainAuthority}
          maxSocial={article.maxSocial}
          articleReadership={article.articleReadership}
        />
        <ArticleBadgeRow
          categories={categories}
          prominence={article.prominence}
          articleNewsCategory={article.articleNewsCategory}
          tierLevel={article.tierLevel}
          onSave={(value: ArticleCategoryUpdateProp) => {
            if (onChange) onChange({ articleId: article.id ?? 0, ...value });
          }}
        />
      </div>
      <Separator orientation="vertical" className="mt-5 max-h-40" />
      <ScoreDisplay
        maxScore={article.maxScore}
        maxDomainAuthority={article.maxDomainAuthority}
        maxSocial={article.maxSocial}
        staleScore={staleScore}
        articleSentiment={article.articleSentiment}
        articleReadership={article.articleReadership}
        overriddenArticleSentiment={article.overriddenArticleSentiment}
        onSentimentChange={(articleSentiment) => {
          onChange?.({
            articleId: article.id ?? 0,
            sentimentPolarity: articleSentiment?.polarity.toUpperCase(),
            sentimentRationale: articleSentiment?.rationale,
          });
        }}
      />
      {secondSection && (
        <div className="w-full flex flex-col space-y-3 col-span-4 text-xs tracking-wide leading-normal justify-center text-gray-500">
          <ArticleSummary
            summary={summary}
            overriddenSummary={
              article.overriddenSummary
                ? (article.overriddenSummary as string)
                : undefined
            }
            onSummaryChange={onChangeSummary}
          />
          {(article.searchHit || article.firstArticleMention) && <Separator />}
          {article.searchHit ? (
            <div className="gap-2 flex flex-col">
              <b className="uppercase leading-4">Search Hit</b>
              <ArticleMentionText
                className="line-clamp-1"
                term={article.searchHit.term}
                snippet={article.searchHit.mention}
              />
            </div>
          ) : (
            article?.firstArticleMention && (
              <div className="gap-2 flex flex-col">
                <b className="uppercase leading-4">First Mention</b>
                <ArticleMentionText
                  className="line-clamp-1"
                  term={article.firstArticleMention.term}
                  snippet={article.firstArticleMention.snippet}
                />
              </div>
            )
          )}
        </div>
      )}
      <Popover>
        <PopoverTrigger asChild>
          <Button
            variant="ghost"
            size="icon"
            className="z-100 absolute right-5 top-2 text-slate-700 hover:text-slate-800 w-8 h-8"
          >
            <DotsThree className="h-6 w-6" />
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-[100px] p-2">
          <Button
            variant="ghost"
            className="text-text-destructive text-sm flex flex-row justify-start items-center"
            onPress={handleDeleteClick}
          >
            <Trash className="me-2 text-red-750" />
            Delete
          </Button>
        </PopoverContent>
      </Popover>
    </Card>
  );
};

export default FeedArticle;
