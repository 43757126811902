import { ReactComponent as FeedNoItemSvg } from "@/assets/svg/feed-no-item.svg";

const FeedEmptyState = (props: { title: string; body: string }) => {
  return (
    <div className="flex flex-col items-center gap-8 mx-auto mt-[100px]">
      <FeedNoItemSvg />
      <div className="flex flex-col gap-2 text-center">
        <h4 className="text-2xl font-serif">{props.title}</h4>
        <p className="text-slate-600 text-sm">{props.body}</p>
      </div>
    </div>
  );
};

export default FeedEmptyState;
