import FeedItem from "@/components/news/feed/FeedItem";
import { FeedSort } from "@/components/news/feed/FeedSort";
import { Badge } from "@/components/ui/data-display/badge";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "@/components/ui/navigation/breadcrumb";
import { StoryQuery } from "@/data-access/storyData";
import useArticleDeletionStore from "@/store/articleDeleteSlice.ts";
import useFeedStore, { useFeedName } from "@/store/useFeedStore";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "urql";
import { FilterDays } from "../../components/news/shared/FilterDays";
import StoryHeader from "./StoryHeader";

const StoryPage = () => {
  const { feedId, storyId } = useParams();

  const { filterDays, sortOrder, updateDateRange } = useFeedStore((state) => ({
    filterDays: state.filterDays,
    sortOrder: state.sortOrder,
    updateDateRange: state.updateDateRange,
  }));

  const feedName = useFeedName();

  const navigate = useNavigate();

  const [{ data }] = useQuery({
    query: StoryQuery,
    variables: {
      storyId: +(storyId ?? 0),
      feedId: +(feedId ?? 0),
      sortOrder,
      filterDays,
    },
    requestPolicy: "cache-and-network",
    pause: !storyId,
  });

  const [setFeedArticleRemoveModalOpen] = useArticleDeletionStore((state) => [
    state.setDeleteModalOpen,
  ]);

  const handleNavigateBack = () => {
    navigate(`/newsfeeds/${feedId}`); // Navigate to the newsfeed route
  };

  return (
    <div className="h-screen grid grid-rows-[auto_1fr] overflow-hidden">
      <div className="sticky top-0 bg-white">
        {storyId && data?.storyData && (
          <div className="px-9 py-4 font-bold flex items-center justify-between border-b border-slate-200">
            <div className="flex gap-4 items-center">
              <Breadcrumb>
                <BreadcrumbList>
                  <BreadcrumbItem>
                    <BreadcrumbLink onClick={handleNavigateBack}>
                      {feedName || "News"}
                    </BreadcrumbLink>
                  </BreadcrumbItem>
                  <BreadcrumbSeparator />
                  <BreadcrumbItem>
                    <BreadcrumbPage>{data.storyData.headline}</BreadcrumbPage>
                  </BreadcrumbItem>
                </BreadcrumbList>
              </Breadcrumb>
              <Badge status={false}>
                {data?.storyData?.storyItems?.length}
                {data?.storyData?.storyItems?.length !== 1
                  ? " Articles"
                  : " Article"}
              </Badge>
            </div>
            <div className="flex flex-row items-center gap-2">
              <FilterDays
                filterDays={filterDays.toString()}
                updateDateRange={updateDateRange}
              />
            </div>
          </div>
        )}
      </div>
      <main className="overflow-y-auto py-8">
        {storyId && data?.storyData && (
          <StoryHeader storyData={data?.storyData} />
        )}
        <div className="flex items-center justify-between pl-10 max-w-[950px] pb-6 mx-auto">
          <h4>
            {data?.storyData?.storyItems?.length !== 1
              ? " Articles"
              : " Article"}
            ({data?.storyData?.storyItems?.length})
          </h4>
          <FeedSort />
        </div>
        <div className="lg:max-w-[900px] lg:mx-auto mx-8 flex flex-col gap-9">
          {storyId &&
            feedId &&
            data?.storyData.storyItems?.map((item) => {
              return (
                <FeedItem
                  key={item.id}
                  setFeedArticleRemoveModalOpen={setFeedArticleRemoveModalOpen}
                  {...item}
                />
              );
            })}
        </div>
      </main>
    </div>
  );
};

export default StoryPage;
