import { isDevelopment } from "@/config";
import { type StateCreator, create } from "zustand";
import { devtools } from "zustand/middleware";

export type FeedPanelSlice = {
  isOpen: boolean;
  mode: "add" | "edit" | null;
  openAddPanel: () => void;
  openEditPanel: () => void;
  closePanel: () => void;
};

export const createFeedPanelSlice: StateCreator<
  FeedPanelSlice,
  [["zustand/devtools", never]],
  []
> = (set) => ({
  isOpen: false,
  mode: null,
  openAddPanel: () => set({ isOpen: true, mode: "add" }),
  openEditPanel: () => set({ isOpen: true, mode: "edit" }),
  closePanel: () => set({ isOpen: false, mode: null }),
});

const useFeedPanelStore = create<FeedPanelSlice>()(
  devtools((...a) => ({ ...createFeedPanelSlice(...a) }), {
    name: "feedStore",
    enabled: isDevelopment,
    store: "feed",
  }),
);

export default useFeedPanelStore;
