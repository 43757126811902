import { z } from "zod";

export const createFormSchema = z.object({
  name: z.string().min(2, {
    message: "Campaign Name must be at least 2 characters.",
  }),
  url: z.string().url({ message: "Please enter a valid URL" }),
  channel: z.enum(["linkedin", "facebook", "instagram"]).default("linkedin"),
  goal: z.string(),
});
