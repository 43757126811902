import * as Sentry from "@sentry/react";
import { useRef, useState } from "react";
import { createHeaders } from "./authenticatedFetch";
import { apiEndpointBaseUrl } from "./constants";

interface DownloadFileProps {
  readonly onError: (e: unknown) => void;
  readonly getFileName: () => string;
}

interface DownloadedFileInfo {
  readonly download: (endpoint: string, tenant: string) => Promise<void>;
  readonly ref: React.MutableRefObject<HTMLAnchorElement | null>;
  readonly fileName?: string;
  readonly fileUrl?: string;
  readonly loading: boolean;
}

export const useDownloadFile = ({
  onError,
  getFileName,
}: DownloadFileProps): DownloadedFileInfo => {
  const ref = useRef<HTMLAnchorElement>(null);
  const [fileUrl, setFileUrl] = useState<string>();
  const [fileName, setFileName] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);

  const download = async (endpoint: string, tenant: string) => {
    try {
      setLoading(true); // Set loading to true at the start
      const token = window.localStorage.getItem("access_token") || "";
      const url = `${apiEndpointBaseUrl}${endpoint}`;
      const headers = await createHeaders("GET", token, tenant);
      const resp = await fetch(url, {
        method: "GET",
        headers,
      });
      const data = await resp.blob();
      const fileUrl = URL.createObjectURL(data);
      setFileUrl(fileUrl);
      setFileName(getFileName());
      setTimeout(() => {
        ref.current?.click();
        URL.revokeObjectURL(url);
      }, 300);
    } catch (error) {
      Sentry.captureException(error);
      onError(error);
    } finally {
      setLoading(false);
    }
  };

  return { download, ref, fileUrl, fileName, loading };
};
