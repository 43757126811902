import { cn } from "@/lib/utils";

const ArticleMentionText = ({
  snippet,
  term,
  className = "",
}: { snippet: string; term: string; className?: string }) => {
  return (
    <p className={cn("text-xs tracking-wide text-slate-500", className)}>
      "...
      {snippet?.split(term).map((part, index, array) =>
        index !== array.length - 1 ? (
          // biome-ignore lint/suspicious/noArrayIndexKey: <we do not have IDs for splitting on mention>
          <span key={index}>
            {part}
            <span className="bg-yellow-100 p-0.5 -mx-0.5 text-gray-500">
              {term}
            </span>
          </span>
        ) : (
          part
        ),
      ) ?? ""}
      ..."
    </p>
  );
};

export default ArticleMentionText;
