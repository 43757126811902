import {
  SearchField,
  SearchFieldClear,
  SearchFieldInput,
} from "@/components/ui/controls/searchfield";
import { MagnifyingGlass, X } from "@phosphor-icons/react";
import React from "react";

type SearchBarProps = {
  searchTerm: string;
  title: string;
  onSearchChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onClearSearch: () => void;
  isOpen: boolean;
};

const SearchBar: React.FC<SearchBarProps> = ({
  searchTerm,
  title,
  onSearchChange,
  onClearSearch,
  isOpen,
}) => {
  return (
    <div className="transition-all bg-white z-10 top-0 pl-1.5 pt-0.5 pr-0.5 sticky">
      <SearchField
        className="h-7 border-0 rounded-none mt-0.5 px-0 focus-within:border-slate-700/40 focus-within:ring-0"
        aria-label="search"
      >
        <MagnifyingGlass
          aria-hidden
          className="h-[14px] w-[14px] text-slate-300"
        />
        <SearchFieldInput
          className="pl-2 text-xs text-ellipsis"
          placeholder={`Search ${title.toLowerCase()}`}
          value={searchTerm}
          onChange={onSearchChange}
          tabIndex={isOpen ? 0 : -1}
        />
        <SearchFieldClear onPress={onClearSearch}>
          <X aria-hidden className="h-4 w-4" />
        </SearchFieldClear>
      </SearchField>
    </div>
  );
};

export default SearchBar;
