import Card from "@/components/ui/card";
import { Separator } from "@/components/ui/controls/separator";
import { Skeleton } from "@/components/ui/feedback/skeleton";
import { DotsThree } from "@phosphor-icons/react";

const FeedArticleSkeleton = () => {
  return (
    <Card className="p-5 text-left md:max-w-[900px] w-full relative grid grid-cols-[auto,1fr,1px,68px] grid-rows-[auto,1fr] gap-3 pb-2">
      <Skeleton className="w-72 h-48" />
      <div className="flex flex-col justify-between space-y-3">
        <div className="space-y-3 flex flex-col justify-start">
          <Skeleton className="h-7 w-3/4 bg-slate-200/50" />
          <div className="flex gap-1">
            <Skeleton className="h-5 w-5" />
            <Skeleton className="h-5 w-32 " />
            <Skeleton className="h-5 w-5" />
          </div>
          <Skeleton className="h-4 w-24 " />
          <div className="flex gap-1.5">
            <Skeleton className="h-6 w-20 bg-slate-50" />
            <Skeleton className="h-6 w-20 bg-green-50/50" />
            <Skeleton className="h-6 w-16 bg-yellow-50/50" />
          </div>
        </div>
      </div>
      <Separator orientation="vertical" />
      <div className="flex flex-col gap-1 w-11">
        <Skeleton className="h-11 w-11 bg-blue-50" />
        <Skeleton className="h-5 w-9 bg-slate-100/50" />
        <Skeleton className="h-5 w-7 bg-slate-100/50" />
        <DotsThree className="h-6 w-6 rotate-90 absolute right-3 top-6 text-slate-200" />
      </div>
      <div className="col-span-4 flex flex-col gap-1">
        <Skeleton className="h-[14.5px] w-20 col-span-4 bg-slate-200/50" />
        <Skeleton className="h-[18px] w-full " />
        <Skeleton className="h-[18px] w-1/2 mb-3" />
      </div>
    </Card>
  );
};

export default FeedArticleSkeleton;
