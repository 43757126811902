import { Button } from "@/components/ui/button";
import { cn } from "@/lib/utils";
import { CaretDown } from "@phosphor-icons/react";
import type React from "react";

interface FilterHeaderProps {
  title: string;
  isOpen: boolean;
  onToggle: () => void;
  onAddTopic: () => void;
  length: number;
}

// List of filters that should show count
const filtersWithCount = [
  "Publications",
  "Themes",
  "Top Topics",
  "People",
  "Authors",
  "Initiatives",
  "Events",
  "Topics",
] as const;

// Base container styles
const containerStyles = cn(
  // Typography
  "text-sm font-thin text-gray-800",

  // Layout
  "flex items-center h-6 overflow-hidden",
  "min-w-[125px]",

  // Focus styles
  "ring-offset-white",
  "focus-visible:ring-offset-2 focus-visible:ring-2 focus-visible:ring-blue-750 focus-visible:outline-none",

  // Responsive
  "md:block", // Show on desktop
  "hidden", // Hide on mobile
);

// Title text styles
const titleStyles = cn(
  "inline-block min-w-[125px]",
  "overflow-hidden text-ellipsis whitespace-nowrap",
);

// Add button styles
const addButtonStyles = cn(
  // Base
  "ml-2 px-2 h-6 py-1",

  // Typography
  "text-xs text-slate-700",

  // Visual
  "bg-white border border-slate-200 rounded-md shadow-sm",

  // States
  "hover:bg-slate-50",
  "focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500",
);

const FilterHeader: React.FC<FilterHeaderProps> = ({
  title,
  isOpen,
  onToggle,
  onAddTopic,
  length,
}) => {
  // Determine if count should be shown based on filter title
  const getTitleAndCount = () => {
    if (
      !filtersWithCount.includes(title as (typeof filtersWithCount)[number])
    ) {
      return title;
    }
    return `${title} (${length})`;
  };

  return (
    <div className={containerStyles}>
      <Button
        size="icon"
        variant="ghost"
        className="size-[25px]"
        onPress={onToggle}
      >
        <CaretDown
          size={16}
          className={cn("transition-transform", {
            "rotate-180": isOpen,
          })}
        />
      </Button>

      <span className={titleStyles}>{getTitleAndCount()}</span>

      {title === "Top Topics" && (
        <Button
          type="button"
          size="sm"
          variant="ghost"
          onPress={onAddTopic}
          className={addButtonStyles}
        >
          Add
        </Button>
      )}
    </div>
  );
};

export default FilterHeader;
