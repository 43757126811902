import { ReactComponent as EmptyFeedsSvg } from "@/assets/svg/empty-feeds.svg";

const NewsEmptyState = () => {
  return (
    <div className="flex flex-col w-full items-center gap-8">
      <EmptyFeedsSvg />
      <div className="flex flex-col gap-2 text-center">
        <h4 className="text-2xl font-serif">No newsfeeds yet</h4>
        <p className="text-slate-600 text-sm">
          Add a feed to start discovering your news
        </p>
      </div>
    </div>
  );
};

export default NewsEmptyState;
