import { isDevelopment } from "@/config";
import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { type PublishersSlice, createPublishersSlice } from "./publishersSlice";

export const usePublisherStore = create<PublishersSlice>()(
  devtools(
    (set, get, api) => ({
      ...createPublishersSlice(set, get, api),
    }),
    {
      name: "publisherStore",
      enabled: isDevelopment,
      store: "publisher",
    },
  ),
);
export default usePublisherStore;
