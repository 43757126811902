import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/textfield";
import { Trash } from "@phosphor-icons/react";

interface TopicProps {
  topic: string;
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  onChange: (e: any) => void;
  onRemove: () => void;
  disabled: boolean;
}

const Topic = ({ topic, onChange, onRemove, disabled }: TopicProps) => {
  return (
    <div className="flex gap-2 items-center mb-2">
      <Input
        className="flex-grow"
        value={topic}
        onChange={onChange}
        autoFocus
        disabled={disabled}
      />
      <Button
        variant="secondary"
        size="icon"
        onPress={onRemove}
        isDisabled={disabled}
      >
        <Trash className="h-4 w-4" />
      </Button>
    </div>
  );
};

export default Topic;
