import useAuth from "@/store/authSlice";
import * as Sentry from "@sentry/react";
import { useEffect } from "react";
import { loadDatadog } from "./datadog";

export const useUserScope = () => {
  const { loggedIn, id, email, company, employee } = useAuth((state) => ({
    ...state.user,
    loggedIn: state.loggedIn,
    company: state.user.default_tenant?.slug,
    employee: state.isEmployee,
  }));

  useEffect(() => {
    Sentry.setUser({
      id,
      email,
      company,
      loggedIn,
      employee,
    });

    loadDatadog().then((datadogRum) => {
      datadogRum.setUser({
        id: id.toString(),
        email,
        company,
        loggedIn,
        employee,
      });
    });
  }, [loggedIn, id, email, company, employee]);
};
