import toast from "@/components/ui/toast";
import { readFragment } from "@/data-access/graphql";
import {
  CREATE_ARTICLE_SUBSCRIPTION,
  CREATE_FEED_MUTATION,
  type CreateFeedMutationDataType,
  DiscoveredTagQuery,
  type DomainOrReadershipConfig,
  FEED_MUTATION,
  type FeedMutationDataType,
  type FeedWithAugTypes,
  NewsFeedV2Query,
  type SideBarQueryFeedType,
  UpdateArticleMutation,
} from "@/data-access/news/newsFeeds";
import {
  type TierFragmentType,
  tierConfigurationFragment,
  tierFragment,
} from "@/data-access/news/tier";
import { client } from "@/lib/urqlProvider";
import { getPrettyName } from "@/lib/utils/prettyName";
import type { ArticleEditFn } from "@/types/article";
import type { SortOptions } from "@/types/shared";
import { format } from "date-fns";
import type { StateCreator } from "zustand";
import {
  getDaysParametersFromUrl,
  getEndDateFromUrl,
  getIsCustomDaysRangeFromUrl,
  getSearchTermsFromUrl,
  getSortByFromUrl,
  getStartDateFromUrl,
  setCustomRangeParametersToUrl,
  setDaysParametersToUrl,
  setSearchTermsToUrl,
  setSortByToUrl,
} from "../components/news/shared/utils";
import {
  type CategoryAppliedFilter,
  TIER_1,
  TIER_2,
  TIER_3,
  calculateCounts,
  filterFeedItems,
  initCategoryAppliedFilters,
  setCountsForThemes,
  updateAppliedFiltersToUrl,
} from "./feedHelpers";

export type FeedSlice = {
  creatingOrEditingFeed: boolean;
  createOrEditFeedState: "initial" | "complete";
  filterFeedIdSearch: [
    number,
    searchHit?: {
      mention: string;
      term: string;
    },
  ][];
  feedSidebarData: SideBarQueryFeedType;
  fetchingFeed: boolean;
  filterDays: number;
  isCustomDaysRange: boolean;
  startDate?: Date;
  endDate?: Date;
  removalArr: number[];
  sortOrder: SortOptions;
  searchTerms: string;
  feedItems: Partial<FeedWithAugTypes>[];
  categoryAppliedFilters: CategoryAppliedFilter;
  feedId: number | null;
  tier2Max: number;
  tier3Max: number;
  refreshingScore: boolean;
  addArticle: (url: string) => Promise<void>;
  updateArticle: ArticleEditFn;
  createFeed: (data: CreateFeedMutationDataType) => Promise<void>;
  editFeed: (data: FeedMutationDataType) => Promise<void>;
  fetchFeed: () => void;
  unsubscribeFetchFeed: () => void;
  setFeedId: (feedId: number | null) => void;
  updateDateRange: (
    days?: number,
    startDate?: Date,
    endDate?: Date,
    isCustomDaysRange?: boolean,
  ) => void;
  removeArticles: (ids: number[]) => void;
  updateFilterAndSearch: () => void;
  isFiltered: () => boolean;
  changeSort: (order: SortOptions) => void;
  filterBySearchTerms: (searchTerms: string) => void;
  updateCategoryAppliedFilters: (categoryApps: CategoryAppliedFilter) => void;
  updateFeedSideBarData: (data: SideBarQueryFeedType) => void;
  updateSelectedFilters: (categoryKey: string, selected: string[]) => void;
  updateRelationship: (categoryKey: string, relationship: "AND" | "OR") => void;
  tierConfig: Record<string, number>;
  setTierConfig: (config: Record<string, number>) => void;
};

export type FilterItem = {
  id: string;
  value: string;
  count: number[];
  selected: boolean;
};

export type DerivedFilterItem = {
  id: string;
  value: string;
  totalCount: number;
  count: number;
  selected: boolean;
};

const DEFAULT_FILTER_DAYS = 30;

export const createFeedSlice: StateCreator<
  FeedSlice,
  [["zustand/devtools", never]],
  []
> = (set, get) => ({
  createOrEditFeedState: "initial",
  creatingOrEditingFeed: false,
  feedSidebarData: [],
  searchTerms: getSearchTermsFromUrl() ?? "",
  fetchingFeed: false,
  refreshingScore: false,
  filterDays: getDaysParametersFromUrl() || DEFAULT_FILTER_DAYS,
  isCustomDaysRange: getIsCustomDaysRangeFromUrl(),
  startDate: getStartDateFromUrl(),
  endDate: getEndDateFromUrl(),
  categoryAppliedFilters: {} as CategoryAppliedFilter,
  feedItems: [],
  sortOrder: (getSortByFromUrl() as SortOptions) || "SCORE_DESC",
  feedName: "",
  feedId: null,
  feedTerms: [],
  knownTags: [],
  tier2Max: 80,
  tier3Max: 60,
  removalArr: [],
  unsubscribeFetchFeed: () => {},
  filterFeedIdSearch: [],

  createFeed: async (data) => {
    set({
      creatingOrEditingFeed: true,
      createOrEditFeedState: "initial",
    });
    client
      .mutation(CREATE_FEED_MUTATION, {
        data,
      })
      .subscribe((res) => {
        if (res.error || res.data?.createFeed.__typename === "OperationInfo") {
          //@ts-ignore
          const messages = res.data?.createFeed?.messages || [
            { message: "An error occurred while creating the feed." },
          ];

          for (const { message } of messages) {
            toast.error(message);
          }

          set({ creatingOrEditingFeed: false });
          return;
        }

        if (res.data?.createFeed.__typename !== "FeedType") return;

        set({
          createOrEditFeedState: "complete",
          creatingOrEditingFeed: false,
          feedId: +res.data.createFeed.id,
        });
      });
  },

  updateArticle: async (changes) => {
    const { articleId, ...overrides } = changes;
    const { headline, summary, author, sentimentPolarity, sentimentRationale } =
      overrides;

    const { feedItems, feedId } = get();

    const updateItemFn = <T extends Partial<FeedWithAugTypes>>(item: T): T => {
      if (item?.articleId !== +articleId) return item;
      if (headline) item.headline = headline;
      if (headline) item.summary = summary;
      if (author && item.articleAuthors?.[0]) {
        const [mainAuthor, ...rest] = item.articleAuthors;
        item.articleAuthors = [{ ...mainAuthor, name: author }, ...rest];
      }
      if (sentimentPolarity && sentimentRationale) {
        item.articleSentiment = {
          polarity: sentimentPolarity,
          rationale: sentimentRationale,
        };
      }
      return item;
    };

    const updatedFeedItems = feedItems.map(updateItemFn);

    set(
      {
        feedItems: updatedFeedItems,
      },
      false,
      "updated article",
    );

    if (!feedId) return;

    const result = await client.mutation(UpdateArticleMutation, {
      input: {
        feedId,
        feedArticleId: +articleId,
        overrides,
      },
    });

    const overrideFeedData = result.data?.overrideFeedArticle;

    if (!overrideFeedData) return;

    if (overrideFeedData.__typename === "OperationInfo") {
      for (const message of overrideFeedData.messages) {
        toast.error(message.message);
      }
    }

    if (
      overrideFeedData.__typename === "FeedArticleType" &&
      overrideFeedData.overrides
    ) {
      if (changes.category) {
        toast.success(
          `Category successfully updated to ${getPrettyName(
            changes.category,
          )}. Refresh to see the update.`,
        );
      }
    }
  },

  isFiltered: () =>
    get().searchTerms.length > 0 ||
    Object.values(get().categoryAppliedFilters)?.some(
      ({ selected }) => selected ?? []?.length > 0,
    ),

  setFeedId: async (feedId) => {
    set(
      {
        feedId,
        fetchingFeed: true,
        createOrEditFeedState: "initial",
        searchTerms: "",
      },
      false,
      "feed id set",
    );
    get().fetchFeed();
  },

  addArticle: async (articleUrl: string) => {
    const feedId = get().feedId;

    if (!feedId) {
      console.error("Could not find the feed ID in the store");
      return;
    }

    client
      .subscription(CREATE_ARTICLE_SUBSCRIPTION, {
        articleUrl,
        feedId: `${feedId}`,
      })
      .subscribe(({ data, error }) => {
        if (error) {
          console.error(error);
        }

        // For debugging
        // if (data) {
        //   console.log({ data });
        // }

        const uiMessage = data?.articleCreation.uiMessage ?? false;

        if (uiMessage) {
          uiMessage.type === "SUCCESS"
            ? toast.success(uiMessage.message)
            : toast.error(uiMessage.message);
        }

        if (
          data?.articleCreation.status === "COMPLETE" ||
          data?.articleCreation.status === "PARTIAL_COMPLETE"
        ) {
          return;
        }
      });
  },

  updateFeedSideBarData: (data) => {
    set(
      {
        feedSidebarData: data,
      },
      false,
      "update feed sidebar w/ data",
    );
  },

  fetchFeed: () => {
    const {
      feedId,
      filterDays,
      isCustomDaysRange,
      unsubscribeFetchFeed,
      startDate,
      endDate,
    } = get();
    if (!feedId) return;

    const categoryAppliedFilters = initCategoryAppliedFilters();

    unsubscribeFetchFeed();

    const dayFilter =
      isCustomDaysRange && startDate && endDate ? undefined : filterDays;

    const DEFAULT_FILTER_DAYS = filterDays;
    const MILLISECONDS_IN_A_DAY = 24 * 60 * 60 * 1000;

    const startDateFormatted = startDate
      ? format(startDate, "yyyy-MM-dd")
      : format(
          new Date(Date.now() - DEFAULT_FILTER_DAYS * MILLISECONDS_IN_A_DAY),
          "yyyy-MM-dd",
        );

    const endDateFormatted = endDate
      ? format(endDate, "yyyy-MM-dd")
      : format(new Date(), "yyyy-MM-dd");

    const newFeedQuery = client.query(NewsFeedV2Query, {
      feedId: `${feedId}`,
      dayFilter,
      startDate: (!dayFilter && startDateFormatted) || undefined,
      endDate: (!dayFilter && endDateFormatted) || undefined,
    });

    const filters = client.query(DiscoveredTagQuery, {
      feedId: feedId,
      startDate: startDateFormatted,
      endDate: endDateFormatted,
    });

    filters.subscribe((data) => {
      setCountsForThemes(
        data.data?.feedArticleTagsByFeed ?? [],
        categoryAppliedFilters,
      );

      set({
        categoryAppliedFilters,
      });
    });

    const { unsubscribe } = newFeedQuery.subscribe((result) => {
      const { data, stale } = result;
      if (!data) return;
      const feedItems = data.feedDataV2;

      function readConfigurationFragment(config: DomainOrReadershipConfig) {
        const settingEnable =
          "enableCustomTierScoring" in config.tenant
            ? config.tenant.enableCustomTierScoring
            : config.tenant.enableReadership;
        const settingTiers = readFragment(tierConfigurationFragment, config);
        return {
          enable: settingEnable,
          frag: readFragment(tierFragment, settingTiers.tiers),
        };
      }

      // Utility function to get upper bound for a specified tier
      function getUpperBound(
        tiers: readonly TierFragmentType[],
        tierNumber: number,
      ) {
        return (
          tiers.find((tier) => tier.tier === tierNumber)?.upperBound ||
          undefined
        );
      }

      // Read tier configuration
      const tierConfig = readConfigurationFragment(data.tierConfiguration);
      const tierReadershipConfig = readConfigurationFragment(
        data.readershipConfiguration,
      );

      // Initialize tier bounds with default values
      let tier2Max = 80;
      let tier3Max = 60;

      // Update bounds if enabled
      if (tierConfig.enable) {
        tier2Max = getUpperBound(tierConfig.frag, 2) || tier2Max;
        tier3Max = getUpperBound(tierConfig.frag, 3) || tier3Max;
      }

      if (tierReadershipConfig.enable) {
        tier2Max = getUpperBound(tierReadershipConfig.frag, 2) || tier2Max;
        tier3Max = getUpperBound(tierReadershipConfig.frag, 3) || tier3Max;
      }

      const { tier1Pub, tier2Pub, tier3Pub } = data;

      const getTierLevelFromPublisher = (publisherId: number) => {
        if (tier1Pub?.publishers.some((pub) => +pub.id === publisherId)) {
          return TIER_1;
        }
        if (tier2Pub?.publishers.some((pub) => +pub.id === publisherId)) {
          return TIER_2;
        }
        if (tier3Pub?.publishers.some((pub) => +pub.id === publisherId)) {
          return TIER_3;
        }
        return null;
      };

      const getTierLevelFromDomainAuthority = (domainAuthority: number) => {
        if (!tierConfig.enable) return null;

        if (domainAuthority < tier3Max) {
          return TIER_3;
        }
        if (domainAuthority < tier2Max) {
          return TIER_2;
        }
        return TIER_1;
      };

      const getTierLevelFromReadership = (readerCount: number) => {
        if (!tierReadershipConfig.enable) return null;

        if (readerCount < tier3Max) {
          return TIER_3;
        }
        if (readerCount < tier2Max) {
          return TIER_2;
        }
        return TIER_1;
      };

      const getTierPriority = (tier: string | null | false) => {
        switch (tier) {
          case TIER_1:
            return 1;
          case TIER_2:
            return 2;
          case TIER_3:
            return 3;
          default:
            return 3;
        }
      };

      const getTierLevel = (
        domainAuthority: number,
        publisherId: number,
        readerCount: number, // Added reader count parameter
      ) => {
        const tierFromPublisher = getTierLevelFromPublisher(publisherId);
        const tierFromDomainAuthority =
          getTierLevelFromDomainAuthority(domainAuthority);
        const tierFromReadership = getTierLevelFromReadership(readerCount);

        // Determine the final tier by considering all factors and enabled configurations
        const tiers: Array<string | null> = [
          tierFromDomainAuthority,
          tierFromReadership,
          tierFromPublisher,
        ].filter(Boolean) as Array<string>;

        if (tiers.length === 0) {
          return TIER_3;
        }

        // Find tier with the highest priority (lowest numerical value)
        const finalTier = tiers.reduce((prev, curr) =>
          getTierPriority(curr) < getTierPriority(prev) ? curr : prev,
        );

        return finalTier ?? TIER_3;
      };

      const updatedFeedItems = feedItems.map((item) => ({
        ...item,
        tierLevel: getTierLevel(
          item.maxDomainAuthority ?? 0,
          item.articlePublisher?.id ?? -1,
          item.articleReadership ?? 500,
        ),
      }));

      calculateCounts(categoryAppliedFilters, updatedFeedItems);

      if (!updatedFeedItems) return;

      set(
        {
          categoryAppliedFilters: categoryAppliedFilters,
          tier2Max,
          tier3Max,
          searchTerms: getSearchTermsFromUrl() ?? "",
          fetchingFeed: false,
          removalArr: [],
          refreshingScore: false,
          feedItems: updatedFeedItems,
        },
        false,
        `data loaded for feed ${feedId}`,
      );
      get().updateFilterAndSearch();
      if (stale) {
        set(
          {
            refreshingScore: true,
          },
          false,
          "data is stale, refreshing score",
        );
      }
    });

    set({ unsubscribeFetchFeed: unsubscribe }, false, "new unsubscribe fn");
  },

  removeArticles: (ids) =>
    set(
      {
        removalArr: [...get().removalArr, ...ids],
      },
      false,
      "removed articles from feed",
    ),

  filterBySearchTerms: (searchTerms) => {
    setSearchTermsToUrl(searchTerms);
    set({ searchTerms }, false, "updated search terms");

    get().updateFilterAndSearch();
  },

  async updateFilterAndSearch() {
    const { feedId, feedItems, searchTerms, categoryAppliedFilters } = get();
    if (!feedId) return;
    const filterFeedIdSearch = await filterFeedItems(
      feedItems,
      searchTerms,
      categoryAppliedFilters,
      +feedId,
    );
    set(
      {
        filterFeedIdSearch,
      },
      false,
      "updated filter search",
    );
  },

  updateDateRange: (filterDays, startDate, endDate, isCustomDaysRange) => {
    if (isCustomDaysRange) setCustomRangeParametersToUrl(startDate, endDate);
    else if (filterDays) setDaysParametersToUrl(filterDays);

    set(
      {
        filterDays,
        startDate: startDate,
        endDate: endDate,
        isCustomDaysRange,
      },
      false,
      "updated range for days",
    );
    get().fetchFeed();
  },

  changeSort: (sortOrder) => {
    setSortByToUrl(sortOrder);
    set({ sortOrder }, false, "updated sort order");
  },

  updateRelationship: (filter, relationship) => {
    const { categoryAppliedFilters, updateFilterAndSearch } = get();
    const filterToUpdate = categoryAppliedFilters[filter];
    if (!filterToUpdate) return;

    filterToUpdate.relationship = relationship;
    set({ categoryAppliedFilters }, false, "updated category applied filters");

    updateFilterAndSearch();
  },

  editFeed: async (data) => {
    const { feedId } = get();

    if (!feedId) return;

    set({
      creatingOrEditingFeed: true,
      createOrEditFeedState: "initial",
    });

    client
      .mutation(FEED_MUTATION, {
        data,
        feedId,
      })
      .subscribe((res) => {
        if (res.data?.updateFeed.__typename === "FeedType" || res.error) {
          set({
            creatingOrEditingFeed: false,
            createOrEditFeedState: res.error ? "initial" : "complete",
          });
        }
      });
  },

  tierConfig: {},
  setTierConfig: (config) =>
    set({ tierConfig: config }, false, "updated tier config"),

  updateCategoryAppliedFilters: (categoryFiltersToUpdate) => {
    const { categoryAppliedFilters } = get();
    const newCategoryAppliedFilters = { ...categoryAppliedFilters };

    for (const [key, value] of Object.entries(categoryFiltersToUpdate)) {
      newCategoryAppliedFilters[key] = {
        ...newCategoryAppliedFilters[key],
        ...value,
      };
    }

    updateAppliedFiltersToUrl(newCategoryAppliedFilters);

    set(
      { categoryAppliedFilters: newCategoryAppliedFilters },
      false,
      "update category applied filters",
    );

    get().updateFilterAndSearch();
  },

  updateSelectedFilters: (categoryKey, selectedOptions) => {
    const { categoryAppliedFilters, updateCategoryAppliedFilters } = get();
    const catFilter = categoryAppliedFilters[categoryKey];

    if (!catFilter) return;

    catFilter.selected = selectedOptions;

    updateAppliedFiltersToUrl(categoryAppliedFilters);
    updateCategoryAppliedFilters(categoryAppliedFilters);
  },
});
