/**
 * Estimates the number of lines for a given text based on the provided font type and screen space.
 *
 * @param {string} text - The text to estimate lines for
 * @param {number} charWidth - The type of font to use for estimation (gt or lora)
 * @param {number} screenWidth - The available screen space
 * @returns {number} The estimated number of lines
 */
export function estimateLines(
  text: string,
  charWidth: number,
  screenWidth: number,
): number {
  const totalCharacters = text.length;
  const maxCharactersPerLine = Math.floor(screenWidth / charWidth);

  return Math.ceil(totalCharacters / maxCharactersPerLine);
}
