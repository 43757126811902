import { graphql } from "./graphql";

export const StoryQuery = graphql(`
  query GetStoryDetailsQuery(
    $feedId: Int!,
    $storyId: Int!,
    $sortOrder: SortOrder!,
    $filterDays: Int!
  ) {
    storyData(
      feedId: $feedId,
      storyId: $storyId,
      sortOrder: $sortOrder,
      daysToFilter: $filterDays
    ) {
      storyItems {
        articleMentions {
          snippet
          term
          location
        }
        articleLastUpdateDate
        articlePublisher {
          id
          name
          url
          logoUrl
        }
        articleUrl
        headline
        imageUrl
        summary
        maxScore
        maxSocial
        maxDomainAuthority
        id
      }
      summary
      headline
      maxScore
    }
  }
`);

export type StoryDataType = ReturnType<
  Exclude<typeof StoryQuery.__apiType, undefined>
>["storyData"];
