import type { FC, ReactNode } from "react";

import { Button } from "@/components/ui/button";
import { CircleNotch } from "@phosphor-icons/react";
import {
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../ui/dialog";

type DialogProps = {
  title: string;
  children: ReactNode | ReactNode[];
  submitText: string;
  className?: string;
  submitLoading?: boolean;
  submittingText?: string;
  onSubmitClick?: () => void;
  onCancelClick?: () => void;
  submitVariant?: "default" | "destructive";
};

const ConfirmationDialog: FC<DialogProps> = ({
  title,
  className,
  children,
  submitLoading,
  submittingText,
  submitText,
  onSubmitClick,
  onCancelClick,
  submitVariant,
}) => {
  return (
    <DialogContent className={className} onClose={onCancelClick}>
      <DialogHeader>
        <DialogTitle className={"font-sans text-base"}>{title}</DialogTitle>
      </DialogHeader>
      <div className="flex flex-col">{children}</div>
      <DialogFooter>
        <Button variant="outline" onPress={onCancelClick}>
          Cancel
        </Button>
        <Button
          type="submit"
          variant={submitVariant || "default"}
          isDisabled={submitLoading}
          className="gap-2"
          onPress={onSubmitClick}
        >
          {submitLoading && (
            <CircleNotch className="animate-spin h-5 w-5 mr-2" />
          )}
          {submitLoading && submittingText ? submittingText : submitText}
        </Button>
      </DialogFooter>
    </DialogContent>
  );
};

export default ConfirmationDialog;
