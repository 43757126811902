import { Skeleton } from "@/components/ui/feedback/skeleton";
import { cn } from "@/lib/utils";
import type { DerivedFilterItem } from "@/store/feedSlice";
import React, {
  memo,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import FilterComponentItem from "./FilterComponentItem";
import AddNewFilter from "./filters/AddNewFilter";
import FilterHeader from "./filters/FilterHeader";
import SearchBar from "./filters/FilterSearchBar";
import ShowMoreButton from "./filters/ShowMoreButton";

const MIN_ITEMS_FOR_SEARCH_AND_SHOW = 11;
const ITEMS_TO_SHOW = 10;

const MemoizedFilterComponentItem = memo(
  FilterComponentItem,
  (prevProps, nextProps) => {
    return (
      prevProps.count === nextProps.count &&
      prevProps.selected === nextProps.selected
    );
  },
);

type FilterComponentProps = {
  title: string;
  filters: DerivedFilterItem[];
  filterByTags: (tags: string[]) => void;
  loading: boolean;
  sorted: boolean;
  editKnownTag?: (tags: string[]) => void;
  handleEditClick?: (tier: string) => Promise<void>;
};

const FilterComponent: React.FC<FilterComponentProps> = ({
  title,
  filters,
  filterByTags,
  loading,
  sorted,
  editKnownTag,
  handleEditClick,
}) => {
  const selectedFiltersRef = useRef<Set<string>>(new Set());

  const [searchTerm, setSearchTerm] = useState<string>("");
  const [isOpen, setIsOpen] = useState(true);
  const [showMore, setShowMore] = useState(false);
  const [displayAddTopic, setDisplayAddTopic] = useState(false);
  const [contentHeight, setContentHeight] = useState<string | number>("auto");
  const contentRef = useRef<HTMLDivElement>(null);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const sortedFilterIdsRef = useRef<string[]>([]);
  const showSearch = filters.length > MIN_ITEMS_FOR_SEARCH_AND_SHOW;

  const updateContentHeight = useCallback(() => {
    if (contentRef.current) {
      const itemCount = contentRef.current.childElementCount;
      const addHeight = showSearch ? 5 : 0;
      setContentHeight(itemCount * 28 + addHeight); // Each item is 28 px
    }
  }, [showSearch]);

  useLayoutEffect(() => {
    selectedFiltersRef.current = new Set(
      filters.filter((option) => option.selected).map((option) => option.id),
    );
    updateContentHeight();
  }, [filters, searchTerm, showMore, updateContentHeight]);

  const handleFilterChange = (filterItem: DerivedFilterItem) => {
    if (selectedFiltersRef.current.has(filterItem.id)) {
      selectedFiltersRef.current.delete(filterItem.id);
    } else {
      selectedFiltersRef.current.add(filterItem.id);
    }
    filterByTags(
      [...selectedFiltersRef.current].map(
        (id) => filters.find((filter) => filter.id === id)?.value ?? "",
      ),
    );
  };

  const handleAddTopic = () => {
    setDisplayAddTopic(!displayAddTopic);
  };

  const handleStopAddingTopic = () => {
    setDisplayAddTopic(false);
  };

  const filteredFilters = useMemo(() => {
    let filteredFilters = filters;
    const anyItemSelected = filters.some((filter) => filter.selected);
    const hasPreviousSort = sortedFilterIdsRef.current.length > 0;

    const compareFilters = (a: DerivedFilterItem, b: DerivedFilterItem) => {
      if (a.count !== b.count) return b.count - a.count;
      return a.value.localeCompare(b.value);
    };

    if (searchTerm) {
      filteredFilters = filteredFilters.filter((filter) =>
        filter.value.toLowerCase().includes(searchTerm.toLowerCase()),
      );
    }
    if (sorted && (!anyItemSelected || !hasPreviousSort)) {
      filters.sort(compareFilters);
      sortedFilterIdsRef.current = filters.map((fi) => fi.id);
    }

    if (hasPreviousSort) {
      filteredFilters.sort(
        (a, b) =>
          sortedFilterIdsRef.current.indexOf(a.id) -
          sortedFilterIdsRef.current.indexOf(b.id),
      );
    }

    return filteredFilters;
  }, [filters, searchTerm, sorted]);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
    setShowMore(false);
  };

  const handleClearSearch = () => {
    setSearchTerm("");
    setShowMore(false);
  };

  useEffect(() => {
    updateContentHeight();
  }, [isOpen, updateContentHeight, filteredFilters]);

  if (loading) {
    return (
      <div className="w-full my-3 first-of-type:mt-2 last-of-type:mb-0 pl-4">
        <Skeleton className="w-1/2 h-6 mb-2" />
        <Skeleton className="w-full h-8" />
      </div>
    );
  }

  const addEditFilter = (updatedValue: string, filterId?: string) => {
    if (editKnownTag) {
      const updatedFilters = filterId
        ? filters.map((fil, index) => {
            if (String(index) === filterId) return updatedValue; // Assuming filterId can be the index in this case
            return fil.value;
          })
        : [...filters.map((fil) => fil.value), updatedValue];

      editKnownTag(updatedFilters);
    }
  };

  return (
    <div className="w-full my-3 first-of-type:mt-2 last-of-type:mb-0 pl-2 relative">
      <FilterHeader
        title={title}
        isOpen={isOpen}
        onToggle={() => {
          setIsTransitioning(true);
          setIsOpen(!isOpen);
        }}
        onAddTopic={handleAddTopic}
        length={filters.length}
      />
      <div
        className={cn(
          "transition-all duration-200",
          isOpen ? "max-h-[341px] overflow-y-auto" : "max-h-0 overflow-hidden",
          isTransitioning && "overflow-hidden",
        )}
        style={{
          minHeight: isOpen ? contentHeight : 0,
        }}
        ref={contentRef}
        onTransitionEnd={() => setIsTransitioning(false)}
      >
        {showSearch && (
          <SearchBar
            searchTerm={searchTerm}
            title={title}
            onSearchChange={handleSearchChange}
            onClearSearch={handleClearSearch}
            isOpen={isOpen}
          />
        )}
        {(filteredFilters.length >= MIN_ITEMS_FOR_SEARCH_AND_SHOW && !showMore
          ? filteredFilters.slice(0, ITEMS_TO_SHOW)
          : filteredFilters
        ).map((filter) => (
          <MemoizedFilterComponentItem
            key={filter.id}
            title={filter.value}
            count={filter.count}
            selected={filter.selected}
            toggleFilter={() => handleFilterChange(filter)}
            tabIndex={isOpen ? 0 : -1}
            handleEditClick={handleEditClick}
            confirmValue={(update) => addEditFilter(update, filter.id)}
            confirmRemove={
              editKnownTag
                ? () =>
                    editKnownTag(
                      filters
                        .filter((fil) => fil.id === filter.id)
                        .map((fil) => fil.value),
                    )
                : undefined
            }
          />
        ))}
        {displayAddTopic && addEditFilter && (
          <AddNewFilter
            onConfirm={(newValue) => {
              handleAddTopic();
              addEditFilter(newValue);
            }}
            onCancel={handleStopAddingTopic}
          />
        )}
        {filteredFilters.length > MIN_ITEMS_FOR_SEARCH_AND_SHOW && (
          <ShowMoreButton
            showMore={showMore}
            onToggle={() => setShowMore((prev) => !prev)}
          />
        )}
        {filteredFilters.length === 0 && (
          <p className="text-sm text-slate-500 pl-2">
            No {title.toLowerCase()} found
          </p>
        )}
      </div>
    </div>
  );
};

export default memo(FilterComponent, (prevProps, nextProps) => {
  // Check if arrays have the same length
  if (prevProps.filters.length !== nextProps.filters.length) return false;

  // Check if loading state changed
  if (prevProps.loading !== nextProps.loading) return false;

  // Check if sorted state changed
  if (prevProps.sorted !== nextProps.sorted) return false;

  // Check if title changed
  if (prevProps.title !== nextProps.title) return false;

  // Deep compare filters array for relevant changes
  const prevFiltersState = prevProps.filters.map((f) => ({
    id: f.id,
    count: f.count,
    selected: f.selected,
  }));

  const nextFiltersState = nextProps.filters.map((f) => ({
    id: f.id,
    count: f.count,
    selected: f.selected,
  }));

  return JSON.stringify(prevFiltersState) === JSON.stringify(nextFiltersState);
});
