import { formattedDateToUTC } from "@/components/news/shared/utils";
import { Separator } from "@/components/ui/controls/separator";
import ButtonGroup from "@/components/ui/molecules/ButtonGroup";
import { EditButton } from "@/components/ui/molecules/EditButton";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/navigation/popover";
import { toast } from "@/components/ui/toast";
import { cn } from "@/lib/utils";
import { Calendar as CalendarIcon } from "@phosphor-icons/react";
import { useEffect, useState } from "react";
import { Calendar } from "../../ui/data-display/calendar";

interface EditableDateProps {
  initialDate?: string | null;
  onSave: (date: Date) => void;
  styles?: string;
  nonEditStyles?: string;
  nonHoverClick?: () => void;
}

const EditableDate: React.FC<EditableDateProps> = ({
  initialDate = new Date().toISOString(),
  onSave,
  styles,
  nonEditStyles,
  nonHoverClick,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [selectedDate, setSelectedDate] = useState<Date | null>(
    initialDate ? new Date(initialDate) : null,
  );

  const handleEditClick = () => {
    setIsEditing(true);
    setIsHovered(false);
  };

  const handleDateChange = (date: Date | undefined) => {
    setSelectedDate(date ?? null);
  };

  const handleDateSave = () => {
    if (!selectedDate) {
      toast.error("Please select a date.");
      return;
    }
    setIsEditing(false);
    onSave(selectedDate);
  };

  const handleDateCancel = () => {
    setIsEditing(false);
    setSelectedDate(initialDate ? new Date(initialDate) : null);
  };

  useEffect(() => {
    setSelectedDate(initialDate ? new Date(initialDate) : null);
  }, [initialDate]);

  return (
    <div className="inline-flex items-start justify-start text-xs text-slate-500">
      {isEditing ? (
        <div className="w-full relative">
          <Popover open={true}>
            <PopoverTrigger asChild>
              <div className="relative py-1 pl-1.5 -ml-1.5 rounded-sm text-xs text-left overflow-hidden leading-tight text-slate-900 line-clamp-3 inline-flex items-center border border-blue-600">
                <p className="min-w-20 text-xs text-gray-800">
                  {selectedDate
                    ? formattedDateToUTC(selectedDate)
                    : "No date selected"}
                </p>
                <CalendarIcon className="fill-slate-700 pr-1" size={18} />
              </div>
            </PopoverTrigger>
            <PopoverContent className="w-auto p-0" align="start">
              <Calendar
                initialFocus
                mode="single"
                defaultMonth={selectedDate ?? new Date()}
                selected={selectedDate ?? undefined}
                onSelect={handleDateChange}
                numberOfMonths={1}
              />
              <Separator />
              <div className="p-2.5">
                <ButtonGroup
                  primaryLabel="Save"
                  primaryAction={handleDateSave}
                  secondaryLabel="Cancel"
                  secondaryAction={handleDateCancel}
                />
              </div>
            </PopoverContent>
          </Popover>
        </div>
      ) : (
        <div
          className={cn(
            "relative py-1 text-xs text-left overflow-hidden leading-tight pl-1.5 pr-1 text-slate-900 line-clamp-3 group -ml-1.5",
            isHovered ? "bg-slate-200/20" : nonEditStyles,
            styles,
          )}
          role={nonHoverClick && "button"}
          onClick={() => !isHovered && nonHoverClick?.()}
          onKeyDown={(e) => {
            if (!isHovered && (e.key === "Enter" || e.key === " ")) {
              nonHoverClick?.();
            }
          }}
        >
          <p className="min-w-24 text-xs text-gray-800">
            {selectedDate
              ? formattedDateToUTC(selectedDate)
              : "No date selected"}
          </p>
          <EditButton
            onEditClick={handleEditClick}
            onHoverChange={setIsHovered}
            className="top-0.5"
          />
        </div>
      )}
    </div>
  );
};

export default EditableDate;
