import type { FeedWithAugTypes } from "@/data-access/news/newsFeeds";
import { estimateLines } from "@/lib/utils/fontMetrics";
import { hasSecondSection, transformSummary } from "./utils";

enum Dimensions {
  DEFAULT_CARD_PADDING_HEIGHT = 28,
  MIN_PIC_HEIGHT = 232,
  MIN_PIC_WIDTH = 310,
  SECOND_SECTION_PADDING = 12,
  DEFAULT_SCREEN_WIDTH = 858,
  DEFAULT_SCORE_WIDTH = 144,
  LORA_CHAR_HEADING_WIDTH = 10,
  ADD_HEADING_LINE_HEIGHT = 25,
  SCORED_DISPLAY_HEIGHT = 84,
  ARTICLE_SUMMARY_FONT_LINE_HEIGHT = 18,
  SECTION_TEXT_HEIGHT = 14,
  MENTION_AND_PADDING_HEIGHT = 48,
  DIVIDER_HEIGHT = 20,
  PERCENT_ADJ_HEIGHT = 1.04,
  FIXED_HEADER_HEIGHT_AND_PADDING = 151,
}

export function calculateTopHeight(
  article: Partial<FeedWithAugTypes>,
  screenWidth: number = Dimensions.DEFAULT_SCREEN_WIDTH,
): number {
  const headlineWidth =
    screenWidth - Dimensions.DEFAULT_SCORE_WIDTH - Dimensions.MIN_PIC_WIDTH;
  const headlineLines = Math.min(
    estimateLines(
      article.headline ?? "",
      Dimensions.LORA_CHAR_HEADING_WIDTH,
      headlineWidth,
    ),
    3,
  );
  const headlineHeight = headlineLines * Dimensions.ADD_HEADING_LINE_HEIGHT;
  const headlineSection =
    headlineHeight + Dimensions.FIXED_HEADER_HEIGHT_AND_PADDING;

  return Math.max(
    Dimensions.MIN_PIC_HEIGHT,
    headlineSection,
    Dimensions.SCORED_DISPLAY_HEIGHT,
  );
}

export const getArticleHeight = (
  article: Partial<FeedWithAugTypes>,
  screenWidth: number = Dimensions.DEFAULT_SCREEN_WIDTH,
) => {
  let height = Dimensions.DEFAULT_CARD_PADDING_HEIGHT;

  height += calculateTopHeight(article, screenWidth);

  if (hasSecondSection(article)) {
    height += Dimensions.SECOND_SECTION_PADDING;
  }

  const mentionOrSearch =
    Boolean(article.firstArticleMention) || Boolean(article.searchHit);

  if (mentionOrSearch) {
    height += Dimensions.MENTION_AND_PADDING_HEIGHT;
  }

  const summaries = transformSummary(article.summary || "Add a summary...");
  let summaryLines = 0;
  for (const summary of summaries) {
    summaryLines += estimateLines(summary, 6, screenWidth);
  }
  height +=
    Dimensions.SECTION_TEXT_HEIGHT +
    summaryLines *
      Dimensions.ARTICLE_SUMMARY_FONT_LINE_HEIGHT *
      Dimensions.PERCENT_ADJ_HEIGHT;

  if (mentionOrSearch) {
    height += Dimensions.DIVIDER_HEIGHT;
  }

  return height * Dimensions.PERCENT_ADJ_HEIGHT;
};
