import { emptySplitApi as api } from "../emptyApi";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    articlesList: build.query<ArticlesListApiResponse, ArticlesListApiArg>({
      query: (queryArg) => ({
        url: `/api/articles/`,
        params: { page: queryArg.page, search: queryArg.search },
      }),
    }),
    articlesCreate: build.mutation<
      ArticlesCreateApiResponse,
      ArticlesCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/articles/`,
        method: "POST",
        body: queryArg.articleWrite,
      }),
    }),
    articlesRetrieve: build.query<
      ArticlesRetrieveApiResponse,
      ArticlesRetrieveApiArg
    >({
      query: (queryArg) => ({ url: `/api/articles/${queryArg.id}/` }),
    }),
    articlesUpdate: build.mutation<
      ArticlesUpdateApiResponse,
      ArticlesUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/articles/${queryArg.id}/`,
        method: "PUT",
        body: queryArg.articleWrite,
      }),
    }),
    articlesPartialUpdate: build.mutation<
      ArticlesPartialUpdateApiResponse,
      ArticlesPartialUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/articles/${queryArg.id}/`,
        method: "PATCH",
        body: queryArg.patchedArticleWrite,
      }),
    }),
    articlesDestroy: build.mutation<
      ArticlesDestroyApiResponse,
      ArticlesDestroyApiArg
    >({
      query: (queryArg) => ({
        url: `/api/articles/${queryArg.id}/`,
        method: "DELETE",
      }),
    }),
    articlesAmplifyRetrieve: build.query<
      ArticlesAmplifyRetrieveApiResponse,
      ArticlesAmplifyRetrieveApiArg
    >({
      query: (queryArg) => ({ url: `/api/articles/${queryArg.id}/amplify/` }),
    }),
    articlesAuthorsList: build.query<
      ArticlesAuthorsListApiResponse,
      ArticlesAuthorsListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/articles/authors/`,
        params: { page: queryArg.page, search: queryArg.search },
      }),
    }),
    articlesAuthorsCreate: build.mutation<
      ArticlesAuthorsCreateApiResponse,
      ArticlesAuthorsCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/articles/authors/`,
        method: "POST",
        body: queryArg.author,
      }),
    }),
    articlesAuthorsRetrieve: build.query<
      ArticlesAuthorsRetrieveApiResponse,
      ArticlesAuthorsRetrieveApiArg
    >({
      query: (queryArg) => ({ url: `/api/articles/authors/${queryArg.id}/` }),
    }),
    articlesAuthorsUpdate: build.mutation<
      ArticlesAuthorsUpdateApiResponse,
      ArticlesAuthorsUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/articles/authors/${queryArg.id}/`,
        method: "PUT",
        body: queryArg.author,
      }),
    }),
    articlesAuthorsPartialUpdate: build.mutation<
      ArticlesAuthorsPartialUpdateApiResponse,
      ArticlesAuthorsPartialUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/articles/authors/${queryArg.id}/`,
        method: "PATCH",
        body: queryArg.patchedAuthor,
      }),
    }),
    articlesAuthorsDestroy: build.mutation<
      ArticlesAuthorsDestroyApiResponse,
      ArticlesAuthorsDestroyApiArg
    >({
      query: (queryArg) => ({
        url: `/api/articles/authors/${queryArg.id}/`,
        method: "DELETE",
      }),
    }),
    articlesFeedList: build.query<
      ArticlesFeedListApiResponse,
      ArticlesFeedListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/articles/feed/`,
        headers: { "X-NA-TENANT": queryArg["X-NA-TENANT"] },
        params: {
          company_id: queryArg.companyId,
          last_modified: queryArg.lastModified,
          page: queryArg.page,
          search: queryArg.search,
          tenant_id: queryArg.tenantId,
        },
      }),
    }),
    articlesFeedCreate: build.mutation<
      ArticlesFeedCreateApiResponse,
      ArticlesFeedCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/articles/feed/`,
        method: "POST",
        body: queryArg.feedOld,
        headers: { "X-NA-TENANT": queryArg["X-NA-TENANT"] },
      }),
    }),
    articlesFeedRetrieve: build.query<
      ArticlesFeedRetrieveApiResponse,
      ArticlesFeedRetrieveApiArg
    >({
      query: (queryArg) => ({
        url: `/api/articles/feed/${queryArg.id}/`,
        headers: { "X-NA-TENANT": queryArg["X-NA-TENANT"] },
      }),
    }),
    articlesFeedUpdate: build.mutation<
      ArticlesFeedUpdateApiResponse,
      ArticlesFeedUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/articles/feed/${queryArg.id}/`,
        method: "PUT",
        body: queryArg.feedOld,
        headers: { "X-NA-TENANT": queryArg["X-NA-TENANT"] },
      }),
    }),
    articlesFeedPartialUpdate: build.mutation<
      ArticlesFeedPartialUpdateApiResponse,
      ArticlesFeedPartialUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/articles/feed/${queryArg.id}/`,
        method: "PATCH",
        body: queryArg.patchedFeedOld,
        headers: { "X-NA-TENANT": queryArg["X-NA-TENANT"] },
      }),
    }),
    articlesFeedDestroy: build.mutation<
      ArticlesFeedDestroyApiResponse,
      ArticlesFeedDestroyApiArg
    >({
      query: (queryArg) => ({
        url: `/api/articles/feed/${queryArg.id}/`,
        method: "DELETE",
        headers: { "X-NA-TENANT": queryArg["X-NA-TENANT"] },
      }),
    }),
    articlesFeedArticlesList: build.query<
      ArticlesFeedArticlesListApiResponse,
      ArticlesFeedArticlesListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/articles/feed/${queryArg.id}/articles/`,
        params: {
          language: queryArg.language,
          limit: queryArg.limit,
          offset: queryArg.offset,
          order_by: queryArg.orderBy,
          published_after: queryArg.publishedAfter,
          search: queryArg.search,
        },
      }),
    }),
    articlesFeedKeywordsList: build.query<
      ArticlesFeedKeywordsListApiResponse,
      ArticlesFeedKeywordsListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/articles/feed/keywords/`,
        params: {
          category: queryArg.category,
          feed: queryArg.feed,
          page: queryArg.page,
          search: queryArg.search,
        },
      }),
    }),
    articlesFeedKeywordsCreate: build.mutation<
      ArticlesFeedKeywordsCreateApiResponse,
      ArticlesFeedKeywordsCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/articles/feed/keywords/`,
        method: "POST",
        body: queryArg.feedKeyword,
      }),
    }),
    articlesFeedKeywordsRetrieve: build.query<
      ArticlesFeedKeywordsRetrieveApiResponse,
      ArticlesFeedKeywordsRetrieveApiArg
    >({
      query: (queryArg) => ({
        url: `/api/articles/feed/keywords/${queryArg.id}/`,
      }),
    }),
    articlesFeedKeywordsUpdate: build.mutation<
      ArticlesFeedKeywordsUpdateApiResponse,
      ArticlesFeedKeywordsUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/articles/feed/keywords/${queryArg.id}/`,
        method: "PUT",
        body: queryArg.feedKeyword,
      }),
    }),
    articlesFeedKeywordsPartialUpdate: build.mutation<
      ArticlesFeedKeywordsPartialUpdateApiResponse,
      ArticlesFeedKeywordsPartialUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/articles/feed/keywords/${queryArg.id}/`,
        method: "PATCH",
        body: queryArg.patchedFeedKeyword,
      }),
    }),
    articlesFeedKeywordsDestroy: build.mutation<
      ArticlesFeedKeywordsDestroyApiResponse,
      ArticlesFeedKeywordsDestroyApiArg
    >({
      query: (queryArg) => ({
        url: `/api/articles/feed/keywords/${queryArg.id}/`,
        method: "DELETE",
      }),
    }),
    articlesFeedScoresList: build.query<
      ArticlesFeedScoresListApiResponse,
      ArticlesFeedScoresListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/articles/feed/scores/`,
        params: { page: queryArg.page, search: queryArg.search },
      }),
    }),
    articlesFeedScoresCreate: build.mutation<
      ArticlesFeedScoresCreateApiResponse,
      ArticlesFeedScoresCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/articles/feed/scores/`,
        method: "POST",
        body: queryArg.articleFeedScore,
      }),
    }),
    articlesFeedScoresRetrieve: build.query<
      ArticlesFeedScoresRetrieveApiResponse,
      ArticlesFeedScoresRetrieveApiArg
    >({
      query: (queryArg) => ({
        url: `/api/articles/feed/scores/${queryArg.id}/`,
      }),
    }),
    articlesFeedScoresUpdate: build.mutation<
      ArticlesFeedScoresUpdateApiResponse,
      ArticlesFeedScoresUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/articles/feed/scores/${queryArg.id}/`,
        method: "PUT",
        body: queryArg.articleFeedScore,
      }),
    }),
    articlesFeedScoresPartialUpdate: build.mutation<
      ArticlesFeedScoresPartialUpdateApiResponse,
      ArticlesFeedScoresPartialUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/articles/feed/scores/${queryArg.id}/`,
        method: "PATCH",
        body: queryArg.patchedArticleFeedScore,
      }),
    }),
    articlesFeedScoresDestroy: build.mutation<
      ArticlesFeedScoresDestroyApiResponse,
      ArticlesFeedScoresDestroyApiArg
    >({
      query: (queryArg) => ({
        url: `/api/articles/feed/scores/${queryArg.id}/`,
        method: "DELETE",
      }),
    }),
    articlesImagesList: build.query<
      ArticlesImagesListApiResponse,
      ArticlesImagesListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/articles/images/`,
        params: {
          article: queryArg.article,
          page: queryArg.page,
          search: queryArg.search,
        },
      }),
    }),
    articlesImagesCreate: build.mutation<
      ArticlesImagesCreateApiResponse,
      ArticlesImagesCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/articles/images/`,
        method: "POST",
        body: queryArg.articleImageWrite,
      }),
    }),
    articlesImagesRetrieve: build.query<
      ArticlesImagesRetrieveApiResponse,
      ArticlesImagesRetrieveApiArg
    >({
      query: (queryArg) => ({ url: `/api/articles/images/${queryArg.id}/` }),
    }),
    articlesImagesUpdate: build.mutation<
      ArticlesImagesUpdateApiResponse,
      ArticlesImagesUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/articles/images/${queryArg.id}/`,
        method: "PUT",
        body: queryArg.articleImageWrite,
      }),
    }),
    articlesImagesPartialUpdate: build.mutation<
      ArticlesImagesPartialUpdateApiResponse,
      ArticlesImagesPartialUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/articles/images/${queryArg.id}/`,
        method: "PATCH",
        body: queryArg.patchedArticleImageWrite,
      }),
    }),
    articlesImagesDestroy: build.mutation<
      ArticlesImagesDestroyApiResponse,
      ArticlesImagesDestroyApiArg
    >({
      query: (queryArg) => ({
        url: `/api/articles/images/${queryArg.id}/`,
        method: "DELETE",
      }),
    }),
    articlesPublishersList: build.query<
      ArticlesPublishersListApiResponse,
      ArticlesPublishersListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/articles/publishers/`,
        params: { page: queryArg.page, search: queryArg.search },
      }),
    }),
    articlesPublishersCreate: build.mutation<
      ArticlesPublishersCreateApiResponse,
      ArticlesPublishersCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/articles/publishers/`,
        method: "POST",
        body: queryArg.publisher,
      }),
    }),
    articlesPublishersRetrieve: build.query<
      ArticlesPublishersRetrieveApiResponse,
      ArticlesPublishersRetrieveApiArg
    >({
      query: (queryArg) => ({
        url: `/api/articles/publishers/${queryArg.id}/`,
      }),
    }),
    articlesPublishersUpdate: build.mutation<
      ArticlesPublishersUpdateApiResponse,
      ArticlesPublishersUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/articles/publishers/${queryArg.id}/`,
        method: "PUT",
        body: queryArg.publisher,
      }),
    }),
    articlesPublishersPartialUpdate: build.mutation<
      ArticlesPublishersPartialUpdateApiResponse,
      ArticlesPublishersPartialUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/articles/publishers/${queryArg.id}/`,
        method: "PATCH",
        body: queryArg.patchedPublisher,
      }),
    }),
    articlesPublishersDestroy: build.mutation<
      ArticlesPublishersDestroyApiResponse,
      ArticlesPublishersDestroyApiArg
    >({
      query: (queryArg) => ({
        url: `/api/articles/publishers/${queryArg.id}/`,
        method: "DELETE",
      }),
    }),
    articlesPublishersLogosRetrieve: build.query<
      ArticlesPublishersLogosRetrieveApiResponse,
      ArticlesPublishersLogosRetrieveApiArg
    >({
      query: (queryArg) => ({
        url: `/api/articles/publishers/${queryArg.id}/logos/`,
      }),
    }),
    articlesPublishersLogosCreate: build.mutation<
      ArticlesPublishersLogosCreateApiResponse,
      ArticlesPublishersLogosCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/articles/publishers/logos/`,
        method: "POST",
        body: queryArg.publisherLogo,
      }),
    }),
    articlesPublishersLogosDestroy: build.mutation<
      ArticlesPublishersLogosDestroyApiResponse,
      ArticlesPublishersLogosDestroyApiArg
    >({
      query: (queryArg) => ({
        url: `/api/articles/publishers/logos/${queryArg.id}/`,
        method: "DELETE",
      }),
    }),
    articlesSearchRetrieve: build.query<
      ArticlesSearchRetrieveApiResponse,
      ArticlesSearchRetrieveApiArg
    >({
      query: (queryArg) => ({
        url: `/api/articles/search/`,
        params: { url: queryArg.url },
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as articleApi };
export type ArticlesListApiResponse =
  /** status 200  */ PaginatedArticleReadListRead;
export type ArticlesListApiArg = {
  /** A page number within the paginated result set. */
  page?: number;
  /** A search term. */
  search?: string;
};
export type ArticlesCreateApiResponse = /** status 201  */ ArticleWriteRead;
export type ArticlesCreateApiArg = {
  articleWrite: ArticleWriteWrite;
};
export type ArticlesRetrieveApiResponse = /** status 200  */ ArticleReadRead;
export type ArticlesRetrieveApiArg = {
  /** A unique integer value identifying this article. */
  id: number;
};
export type ArticlesUpdateApiResponse = /** status 200  */ ArticleWriteRead;
export type ArticlesUpdateApiArg = {
  /** A unique integer value identifying this article. */
  id: number;
  articleWrite: ArticleWriteWrite;
};
export type ArticlesPartialUpdateApiResponse =
  /** status 200  */ ArticleWriteRead;
export type ArticlesPartialUpdateApiArg = {
  /** A unique integer value identifying this article. */
  id: number;
  patchedArticleWrite: PatchedArticleWriteWrite;
};
export type ArticlesDestroyApiResponse = unknown;
export type ArticlesDestroyApiArg = {
  /** A unique integer value identifying this article. */
  id: number;
};
export type ArticlesAmplifyRetrieveApiResponse =
  /** status 200  */ ArticleReadRead;
export type ArticlesAmplifyRetrieveApiArg = {
  /** A unique integer value identifying this article. */
  id: number;
};
export type ArticlesAuthorsListApiResponse =
  /** status 200  */ PaginatedAuthorListRead;
export type ArticlesAuthorsListApiArg = {
  /** A page number within the paginated result set. */
  page?: number;
  /** A search term. */
  search?: string;
};
export type ArticlesAuthorsCreateApiResponse = /** status 201  */ AuthorRead;
export type ArticlesAuthorsCreateApiArg = {
  author: Author;
};
export type ArticlesAuthorsRetrieveApiResponse = /** status 200  */ AuthorRead;
export type ArticlesAuthorsRetrieveApiArg = {
  /** A unique integer value identifying this author. */
  id: number;
};
export type ArticlesAuthorsUpdateApiResponse = /** status 200  */ AuthorRead;
export type ArticlesAuthorsUpdateApiArg = {
  /** A unique integer value identifying this author. */
  id: number;
  author: Author;
};
export type ArticlesAuthorsPartialUpdateApiResponse =
  /** status 200  */ AuthorRead;
export type ArticlesAuthorsPartialUpdateApiArg = {
  /** A unique integer value identifying this author. */
  id: number;
  patchedAuthor: PatchedAuthor;
};
export type ArticlesAuthorsDestroyApiResponse = unknown;
export type ArticlesAuthorsDestroyApiArg = {
  /** A unique integer value identifying this author. */
  id: number;
};
export type ArticlesFeedListApiResponse =
  /** status 200  */ PaginatedFeedOldListRead;
export type ArticlesFeedListApiArg = {
  /** Use this header to perform actions in specific Tenant */
  "X-NA-TENANT"?: string;
  companyId?: number;
  lastModified?: string;
  /** A page number within the paginated result set. */
  page?: number;
  /** A search term. */
  search?: string;
  tenantId?: number;
};
export type ArticlesFeedCreateApiResponse = /** status 201  */ FeedOldRead;
export type ArticlesFeedCreateApiArg = {
  /** Use this header to perform actions in specific Tenant */
  "X-NA-TENANT"?: string;
  feedOld: FeedOld;
};
export type ArticlesFeedRetrieveApiResponse = /** status 200  */ FeedOldRead;
export type ArticlesFeedRetrieveApiArg = {
  /** Use this header to perform actions in specific Tenant */
  "X-NA-TENANT"?: string;
  /** A unique integer value identifying this feed old. */
  id: number;
};
export type ArticlesFeedUpdateApiResponse = /** status 200  */ FeedOldRead;
export type ArticlesFeedUpdateApiArg = {
  /** Use this header to perform actions in specific Tenant */
  "X-NA-TENANT"?: string;
  /** A unique integer value identifying this feed old. */
  id: number;
  feedOld: FeedOld;
};
export type ArticlesFeedPartialUpdateApiResponse =
  /** status 200  */ FeedOldRead;
export type ArticlesFeedPartialUpdateApiArg = {
  /** Use this header to perform actions in specific Tenant */
  "X-NA-TENANT"?: string;
  /** A unique integer value identifying this feed old. */
  id: number;
  patchedFeedOld: PatchedFeedOld;
};
export type ArticlesFeedDestroyApiResponse = unknown;
export type ArticlesFeedDestroyApiArg = {
  /** Use this header to perform actions in specific Tenant */
  "X-NA-TENANT"?: string;
  /** A unique integer value identifying this feed old. */
  id: number;
};
export type ArticlesFeedArticlesListApiResponse =
  /** status 200  */ PaginatedArticleReadListRead;
export type ArticlesFeedArticlesListApiArg = {
  id: number;
  /** * `ar` - Arabic
    * `ru` - Russian
    * `nl` - Dutch
    * `de` - German
    * `en` - English
    * `es` - Spanish
    * `fr` - French
    * `he` - Hebrew
    * `it` - Italian
    * `ko` - Korean
    * `no` - Norwegian
    * `fa` - Persian
    * `pl` - Polish
    * `pt` - Portuguese
    * `sv` - Swedish
    * `hu` - Hungarian
    * `fi` - Finnish
    * `da` - Danish
    * `ja` - Japanese
    * `zh` - Chinese
    * `id` - Indonesian
    * `vi` - Vietnamese
    * `sw` - Swahili
    * `tr` - Turkish
    * `el` - Greek
    * `uk` - Ukrainian
    
    * `ar` - Arabic
    * `ru` - Russian
    * `nl` - Dutch
    * `de` - German
    * `en` - English
    * `es` - Spanish
    * `fr` - French
    * `he` - Hebrew
    * `it` - Italian
    * `ko` - Korean
    * `no` - Norwegian
    * `fa` - Persian
    * `pl` - Polish
    * `pt` - Portuguese
    * `sv` - Swedish
    * `hu` - Hungarian
    * `fi` - Finnish
    * `da` - Danish
    * `ja` - Japanese
    * `zh` - Chinese
    * `id` - Indonesian
    * `vi` - Vietnamese
    * `sw` - Swahili
    * `tr` - Turkish
    * `el` - Greek
    * `uk` - Ukrainian */
  language?:
    | "ar"
    | "da"
    | "de"
    | "el"
    | "en"
    | "es"
    | "fa"
    | "fi"
    | "fr"
    | "he"
    | "hu"
    | "id"
    | "it"
    | "ja"
    | "ko"
    | "nl"
    | "no"
    | "pl"
    | "pt"
    | "ru"
    | "sv"
    | "sw"
    | "tr"
    | "uk"
    | "vi"
    | "zh";
  /** Number of results to return per page. */
  limit?: number;
  /** The initial index from which to return the results. */
  offset?: number;
  /** Ordering
    
    * `score` - Score
    * `-score` - Score (descending)
    * `date_published` - Date Published
    * `-date_published` - Date Published (descending) */
  orderBy?: ("-date_published" | "-score" | "date_published" | "score")[];
  publishedAfter?: string;
  /** A search term. */
  search?: string;
};
export type ArticlesFeedKeywordsListApiResponse =
  /** status 200  */ PaginatedFeedKeywordListRead;
export type ArticlesFeedKeywordsListApiArg = {
  /** * `aliases` - Aliases
    * `subsidiaries` - Subsidiaries
    * `products` - Products
    * `employees` - Employees
    * `industry_terms` - Industry Terms
    * `industries` - Industries
    * `trademarks` - Trademarks
    * `locations` - Locations
    
    * `aliases` - Aliases
    * `subsidiaries` - Subsidiaries
    * `products` - Products
    * `employees` - Employees
    * `industry_terms` - Industry Terms
    * `industries` - Industries
    * `trademarks` - Trademarks
    * `locations` - Locations */
  category?:
    | "aliases"
    | "employees"
    | "industries"
    | "industry_terms"
    | "locations"
    | "products"
    | "subsidiaries"
    | "trademarks";
  feed?: number;
  /** A page number within the paginated result set. */
  page?: number;
  /** A search term. */
  search?: string;
};
export type ArticlesFeedKeywordsCreateApiResponse =
  /** status 201  */ FeedKeywordRead;
export type ArticlesFeedKeywordsCreateApiArg = {
  feedKeyword: FeedKeyword;
};
export type ArticlesFeedKeywordsRetrieveApiResponse =
  /** status 200  */ FeedKeywordRead;
export type ArticlesFeedKeywordsRetrieveApiArg = {
  /** A unique integer value identifying this feed keyword. */
  id: number;
};
export type ArticlesFeedKeywordsUpdateApiResponse =
  /** status 200  */ FeedKeywordRead;
export type ArticlesFeedKeywordsUpdateApiArg = {
  /** A unique integer value identifying this feed keyword. */
  id: number;
  feedKeyword: FeedKeyword;
};
export type ArticlesFeedKeywordsPartialUpdateApiResponse =
  /** status 200  */ FeedKeywordRead;
export type ArticlesFeedKeywordsPartialUpdateApiArg = {
  /** A unique integer value identifying this feed keyword. */
  id: number;
  patchedFeedKeyword: PatchedFeedKeyword;
};
export type ArticlesFeedKeywordsDestroyApiResponse = unknown;
export type ArticlesFeedKeywordsDestroyApiArg = {
  /** A unique integer value identifying this feed keyword. */
  id: number;
};
export type ArticlesFeedScoresListApiResponse =
  /** status 200  */ PaginatedArticleFeedScoreListRead;
export type ArticlesFeedScoresListApiArg = {
  /** A page number within the paginated result set. */
  page?: number;
  /** A search term. */
  search?: string;
};
export type ArticlesFeedScoresCreateApiResponse =
  /** status 201  */ ArticleFeedScoreRead;
export type ArticlesFeedScoresCreateApiArg = {
  articleFeedScore: ArticleFeedScore;
};
export type ArticlesFeedScoresRetrieveApiResponse =
  /** status 200  */ ArticleFeedScoreRead;
export type ArticlesFeedScoresRetrieveApiArg = {
  /** A unique integer value identifying this article feed score. */
  id: number;
};
export type ArticlesFeedScoresUpdateApiResponse =
  /** status 200  */ ArticleFeedScoreRead;
export type ArticlesFeedScoresUpdateApiArg = {
  /** A unique integer value identifying this article feed score. */
  id: number;
  articleFeedScore: ArticleFeedScore;
};
export type ArticlesFeedScoresPartialUpdateApiResponse =
  /** status 200  */ ArticleFeedScoreRead;
export type ArticlesFeedScoresPartialUpdateApiArg = {
  /** A unique integer value identifying this article feed score. */
  id: number;
  patchedArticleFeedScore: PatchedArticleFeedScore;
};
export type ArticlesFeedScoresDestroyApiResponse = unknown;
export type ArticlesFeedScoresDestroyApiArg = {
  /** A unique integer value identifying this article feed score. */
  id: number;
};
export type ArticlesImagesListApiResponse =
  /** status 200  */ PaginatedArticleImageReadListRead;
export type ArticlesImagesListApiArg = {
  article?: number;
  /** A page number within the paginated result set. */
  page?: number;
  /** A search term. */
  search?: string;
};
export type ArticlesImagesCreateApiResponse =
  /** status 201  */ ArticleImageWriteRead;
export type ArticlesImagesCreateApiArg = {
  articleImageWrite: ArticleImageWriteWrite;
};
export type ArticlesImagesRetrieveApiResponse =
  /** status 200  */ ArticleImageReadRead;
export type ArticlesImagesRetrieveApiArg = {
  /** A unique integer value identifying this article image. */
  id: number;
};
export type ArticlesImagesUpdateApiResponse =
  /** status 200  */ ArticleImageWriteRead;
export type ArticlesImagesUpdateApiArg = {
  /** A unique integer value identifying this article image. */
  id: number;
  articleImageWrite: ArticleImageWriteWrite;
};
export type ArticlesImagesPartialUpdateApiResponse =
  /** status 200  */ ArticleImageWriteRead;
export type ArticlesImagesPartialUpdateApiArg = {
  /** A unique integer value identifying this article image. */
  id: number;
  patchedArticleImageWrite: PatchedArticleImageWriteWrite;
};
export type ArticlesImagesDestroyApiResponse = unknown;
export type ArticlesImagesDestroyApiArg = {
  /** A unique integer value identifying this article image. */
  id: number;
};
export type ArticlesPublishersListApiResponse =
  /** status 200  */ PaginatedPublisherListRead;
export type ArticlesPublishersListApiArg = {
  /** A page number within the paginated result set. */
  page?: number;
  /** A search term. */
  search?: string;
};
export type ArticlesPublishersCreateApiResponse =
  /** status 201  */ PublisherRead;
export type ArticlesPublishersCreateApiArg = {
  publisher: PublisherWrite;
};
export type ArticlesPublishersRetrieveApiResponse =
  /** status 200  */ PublisherRead;
export type ArticlesPublishersRetrieveApiArg = {
  /** A unique integer value identifying this publisher. */
  id: number;
};
export type ArticlesPublishersUpdateApiResponse =
  /** status 200  */ PublisherRead;
export type ArticlesPublishersUpdateApiArg = {
  /** A unique integer value identifying this publisher. */
  id: number;
  publisher: PublisherWrite;
};
export type ArticlesPublishersPartialUpdateApiResponse =
  /** status 200  */ PublisherRead;
export type ArticlesPublishersPartialUpdateApiArg = {
  /** A unique integer value identifying this publisher. */
  id: number;
  patchedPublisher: PatchedPublisherWrite;
};
export type ArticlesPublishersDestroyApiResponse = unknown;
export type ArticlesPublishersDestroyApiArg = {
  /** A unique integer value identifying this publisher. */
  id: number;
};
export type ArticlesPublishersLogosRetrieveApiResponse =
  /** status 200  */ PublisherRead;
export type ArticlesPublishersLogosRetrieveApiArg = {
  /** A unique integer value identifying this publisher. */
  id: number;
};
export type ArticlesPublishersLogosCreateApiResponse =
  /** status 201  */ PublisherLogoRead;
export type ArticlesPublishersLogosCreateApiArg = {
  publisherLogo: PublisherLogoWrite;
};
export type ArticlesPublishersLogosDestroyApiResponse = unknown;
export type ArticlesPublishersLogosDestroyApiArg = {
  /** A unique integer value identifying this publisher logo. */
  id: number;
};
export type ArticlesSearchRetrieveApiResponse =
  /** status 200  */ ArticleReadRead;
export type ArticlesSearchRetrieveApiArg = {
  /** URL of the article */
  url: string;
};
export type LanguageEnum =
  | "ar"
  | "ru"
  | "nl"
  | "de"
  | "en"
  | "es"
  | "fr"
  | "he"
  | "it"
  | "ko"
  | "no"
  | "fa"
  | "pl"
  | "pt"
  | "sv"
  | "hu"
  | "fi"
  | "da"
  | "ja"
  | "zh"
  | "id"
  | "vi"
  | "sw"
  | "tr"
  | "el"
  | "uk";
export type BlankEnum = "";
export type ArticleRead = {
  url: string;
  headline?: string;
  description?: string;
  lead?: string;
  date_published?: string | null;
  date_modified?: string | null;
  meta_keywords?: string[];
  language?: LanguageEnum | BlankEnum;
};
export type Author = {
  name: string;
};
export type AuthorRead = {
  id: number;
  name: string;
};
export type Publisher = {
  name: string;
  url: string;
};
export type PublisherLogo = {
  publisher: number;
  image?: string;
};
export type PublisherLogoRead = {
  id: number;
  publisher: number;
  image?: string;
};
export type PublisherLogoWrite = {
  publisher: number;
  image?: string;
  source?: string;
};
export type PublisherRead = {
  id: number;
  name: string;
  url: string;
  logos: PublisherLogoRead[];
};
export type PublisherWrite = {
  name: string;
  url: string;
};
export type ArticleImageRead = {
  article: number;
  caption?: string;
  is_main_image?: boolean;
};
export type ArticleImageReadRead = {
  id: number;
  article: number;
  image: string;
  caption?: string;
  is_main_image?: boolean;
};
export type ArticleImageReadWrite = {
  article: number;
  caption?: string;
  is_main_image?: boolean;
  source?: string;
};
export type ArticleSummary = {
  article: number;
  text: string;
};
export type ArticleSummaryRead = {
  id: number;
  article: number;
  text: string;
};
export type ArticleQuote = {
  article: number;
  text: string;
};
export type ArticleQuoteRead = {
  id: number;
  article: number;
  text: string;
};
export type ArticleHeadline = {
  article: number;
  text: string;
};
export type ArticleHeadlineRead = {
  id: number;
  article: number;
  text: string;
};
export type ArticleLead = {
  article: number;
  text: string;
};
export type ArticleLeadRead = {
  id: number;
  article: number;
  text: string;
};
export type ArticleReadRead = {
  id: number;
  url: string;
  headline?: string;
  description?: string;
  authors: AuthorRead[];
  publisher: PublisherRead;
  lead?: string;
  images: ArticleImageReadRead[];
  date_published?: string | null;
  date_modified?: string | null;
  meta_keywords?: string[];
  language?: LanguageEnum | BlankEnum;
  summaries: ArticleSummaryRead[];
  quotes: ArticleQuoteRead[];
  headlines: ArticleHeadlineRead[];
  leads: ArticleLeadRead[];
  score: number;
};
export type ArticleReadWrite = {
  url: string;
  headline?: string;
  description?: string;
  lead?: string;
  date_published?: string | null;
  date_modified?: string | null;
  meta_keywords?: string[];
  language?: LanguageEnum | BlankEnum;
  sources?: string[] | null;
};
export type PaginatedArticleReadList = {
  count?: number;
  next?: string | null;
  previous?: string | null;
  results?: ArticleRead[];
};
export type PaginatedArticleReadListRead = {
  count?: number;
  next?: string | null;
  previous?: string | null;
  results?: ArticleReadRead[];
};
export type PaginatedArticleReadListWrite = {
  count?: number;
  next?: string | null;
  previous?: string | null;
  results?: ArticleReadWrite[];
};
export type ArticleWrite = {
  url: string;
  headline?: string;
  description?: string;
  authors?: (number | null)[];
  publisher?: number | null;
  lead?: string;
  date_published?: string | null;
  date_modified?: string | null;
  meta_keywords?: string[];
  language?: LanguageEnum | BlankEnum;
};
export type ArticleWriteRead = {
  id: number;
  url: string;
  headline?: string;
  description?: string;
  authors?: (number | null)[];
  publisher?: number | null;
  lead?: string;
  images: ArticleImageReadRead[];
  date_published?: string | null;
  date_modified?: string | null;
  meta_keywords?: string[];
  language?: LanguageEnum | BlankEnum;
  summaries: ArticleSummaryRead[];
  quotes: ArticleQuoteRead[];
  headlines: ArticleHeadlineRead[];
  leads: ArticleLeadRead[];
  score: number;
};
export type ArticleWriteWrite = {
  url: string;
  headline?: string;
  description?: string;
  authors?: (number | null)[];
  publisher?: number | null;
  lead?: string;
  date_published?: string | null;
  date_modified?: string | null;
  meta_keywords?: string[];
  language?: LanguageEnum | BlankEnum;
  sources?: string[] | null;
};
export type PatchedArticleWrite = {
  url?: string;
  headline?: string;
  description?: string;
  authors?: (number | null)[];
  publisher?: number | null;
  lead?: string;
  date_published?: string | null;
  date_modified?: string | null;
  meta_keywords?: string[];
  language?: LanguageEnum | BlankEnum;
};
export type PatchedArticleWriteRead = {
  id?: number;
  url?: string;
  headline?: string;
  description?: string;
  authors?: (number | null)[];
  publisher?: number | null;
  lead?: string;
  images?: ArticleImageReadRead[];
  date_published?: string | null;
  date_modified?: string | null;
  meta_keywords?: string[];
  language?: LanguageEnum | BlankEnum;
  summaries?: ArticleSummaryRead[];
  quotes?: ArticleQuoteRead[];
  headlines?: ArticleHeadlineRead[];
  leads?: ArticleLeadRead[];
  score?: number;
};
export type PatchedArticleWriteWrite = {
  url?: string;
  headline?: string;
  description?: string;
  authors?: (number | null)[];
  publisher?: number | null;
  lead?: string;
  date_published?: string | null;
  date_modified?: string | null;
  meta_keywords?: string[];
  language?: LanguageEnum | BlankEnum;
  sources?: string[] | null;
};
export type PaginatedAuthorList = {
  count?: number;
  next?: string | null;
  previous?: string | null;
  results?: Author[];
};
export type PaginatedAuthorListRead = {
  count?: number;
  next?: string | null;
  previous?: string | null;
  results?: AuthorRead[];
};
export type PatchedAuthor = {
  name?: string;
};
export type PatchedAuthorRead = {
  id?: number;
  name?: string;
};
export type NaCategoriesBlacklistEnum =
  | "financial"
  | "press"
  | "sports"
  | "obituary";
export type FeedOld = {
  deleted_on?: string | null;
  /** FeedOld/Search name */
  name?: string;
  /** Usually the same company name, but it can be edited by the user */
  brand_name: string;
  logo?: string | null;
  keywords_blacklist?: string[];
  na_categories_blacklist?: NaCategoriesBlacklistEnum[];
  created_by?: number | null;
  updated_by?: number | null;
  tenant: number;
  publishers_whitelist?: number[];
  publishers_blacklist?: number[];
};
export type Company = {
  name: string;
  description?: string;
  url?: string;
  logo?: string | null;
  created_by?: number | null;
  updated_by?: number | null;
  parent?: number | null;
};
export type CompanyRead = {
  id: number;
  created_at: string;
  updated_at: string;
  name: string;
  description?: string;
  url?: string;
  logo?: string | null;
  created_by?: number | null;
  updated_by?: number | null;
  parent?: number | null;
};
export type CategoryEnum =
  | "aliases"
  | "subsidiaries"
  | "products"
  | "employees"
  | "industry_terms"
  | "industries"
  | "trademarks"
  | "locations";
export type FeedKeyword = {
  title: string;
  category: CategoryEnum;
  weight?: number;
  created_by?: number | null;
  updated_by?: number | null;
  feed: number;
};
export type FeedKeywordRead = {
  id: number;
  category_display: string;
  created_at: string;
  updated_at: string;
  title: string;
  category: CategoryEnum;
  weight?: number;
  created_by?: number | null;
  updated_by?: number | null;
  feed: number;
};
export type FeedOldRead = {
  id: number;
  company: CompanyRead;
  keywords: FeedKeywordRead[];
  deleted_on?: string | null;
  created_at: string;
  updated_at: string;
  /** FeedOld/Search name */
  name?: string;
  /** Usually the same company name, but it can be edited by the user */
  brand_name: string;
  logo?: string | null;
  keywords_blacklist?: string[];
  na_categories_blacklist?: NaCategoriesBlacklistEnum[];
  created_by?: number | null;
  updated_by?: number | null;
  tenant: number;
  publishers_whitelist?: number[];
  publishers_blacklist?: number[];
};
export type PaginatedFeedOldList = {
  count?: number;
  next?: string | null;
  previous?: string | null;
  results?: FeedOld[];
};
export type PaginatedFeedOldListRead = {
  count?: number;
  next?: string | null;
  previous?: string | null;
  results?: FeedOldRead[];
};
export type PatchedFeedOld = {
  deleted_on?: string | null;
  /** FeedOld/Search name */
  name?: string;
  /** Usually the same company name, but it can be edited by the user */
  brand_name?: string;
  logo?: string | null;
  keywords_blacklist?: string[];
  na_categories_blacklist?: NaCategoriesBlacklistEnum[];
  created_by?: number | null;
  updated_by?: number | null;
  tenant?: number;
  publishers_whitelist?: number[];
  publishers_blacklist?: number[];
};
export type PatchedFeedOldRead = {
  id?: number;
  company?: CompanyRead;
  keywords?: FeedKeywordRead[];
  deleted_on?: string | null;
  created_at?: string;
  updated_at?: string;
  /** FeedOld/Search name */
  name?: string;
  /** Usually the same company name, but it can be edited by the user */
  brand_name?: string;
  logo?: string | null;
  keywords_blacklist?: string[];
  na_categories_blacklist?: NaCategoriesBlacklistEnum[];
  created_by?: number | null;
  updated_by?: number | null;
  tenant?: number;
  publishers_whitelist?: number[];
  publishers_blacklist?: number[];
};
export type PaginatedFeedKeywordList = {
  count?: number;
  next?: string | null;
  previous?: string | null;
  results?: FeedKeyword[];
};
export type PaginatedFeedKeywordListRead = {
  count?: number;
  next?: string | null;
  previous?: string | null;
  results?: FeedKeywordRead[];
};
export type PatchedFeedKeyword = {
  title?: string;
  category?: CategoryEnum;
  weight?: number;
  created_by?: number | null;
  updated_by?: number | null;
  feed?: number;
};
export type PatchedFeedKeywordRead = {
  id?: number;
  category_display?: string;
  created_at?: string;
  updated_at?: string;
  title?: string;
  category?: CategoryEnum;
  weight?: number;
  created_by?: number | null;
  updated_by?: number | null;
  feed?: number;
};
export type ArticleFeedScore = {
  score?: number;
  keyword_scores?: {
    [key: string]: any;
  } | null;
  created_by?: number | null;
  updated_by?: number | null;
  article: number;
  feed: number;
};
export type ArticleFeedScoreRead = {
  id: number;
  created_at: string;
  updated_at: string;
  score?: number;
  keyword_scores?: {
    [key: string]: any;
  } | null;
  created_by?: number | null;
  updated_by?: number | null;
  article: number;
  feed: number;
};
export type PaginatedArticleFeedScoreList = {
  count?: number;
  next?: string | null;
  previous?: string | null;
  results?: ArticleFeedScore[];
};
export type PaginatedArticleFeedScoreListRead = {
  count?: number;
  next?: string | null;
  previous?: string | null;
  results?: ArticleFeedScoreRead[];
};
export type PatchedArticleFeedScore = {
  score?: number;
  keyword_scores?: {
    [key: string]: any;
  } | null;
  created_by?: number | null;
  updated_by?: number | null;
  article?: number;
  feed?: number;
};
export type PatchedArticleFeedScoreRead = {
  id?: number;
  created_at?: string;
  updated_at?: string;
  score?: number;
  keyword_scores?: {
    [key: string]: any;
  } | null;
  created_by?: number | null;
  updated_by?: number | null;
  article?: number;
  feed?: number;
};
export type PaginatedArticleImageReadList = {
  count?: number;
  next?: string | null;
  previous?: string | null;
  results?: ArticleImageRead[];
};
export type PaginatedArticleImageReadListRead = {
  count?: number;
  next?: string | null;
  previous?: string | null;
  results?: ArticleImageReadRead[];
};
export type PaginatedArticleImageReadListWrite = {
  count?: number;
  next?: string | null;
  previous?: string | null;
  results?: ArticleImageReadWrite[];
};
export type ArticleImageWrite = {
  article: number;
  image?: string;
  caption?: string;
  is_main_image?: boolean;
};
export type ArticleImageWriteRead = {
  id: number;
  article: number;
  image?: string;
  caption?: string;
  is_main_image?: boolean;
};
export type ArticleImageWriteWrite = {
  article: number;
  image?: string;
  caption?: string;
  is_main_image?: boolean;
  source?: string;
};
export type PatchedArticleImageWrite = {
  article?: number;
  image?: string;
  caption?: string;
  is_main_image?: boolean;
};
export type PatchedArticleImageWriteRead = {
  id?: number;
  article?: number;
  image?: string;
  caption?: string;
  is_main_image?: boolean;
};
export type PatchedArticleImageWriteWrite = {
  article?: number;
  image?: string;
  caption?: string;
  is_main_image?: boolean;
  source?: string;
};
export type PaginatedPublisherList = {
  count?: number;
  next?: string | null;
  previous?: string | null;
  results?: Publisher[];
};
export type PaginatedPublisherListRead = {
  count?: number;
  next?: string | null;
  previous?: string | null;
  results?: PublisherRead[];
};
export type PaginatedPublisherListWrite = {
  count?: number;
  next?: string | null;
  previous?: string | null;
  results?: PublisherWrite[];
};
export type PatchedPublisher = {
  name?: string;
  url?: string;
};
export type PatchedPublisherRead = {
  id?: number;
  name?: string;
  url?: string;
  logos?: PublisherLogoRead[];
};
export type PatchedPublisherWrite = {
  name?: string;
  url?: string;
};
export const {
  useArticlesListQuery,
  useArticlesCreateMutation,
  useArticlesRetrieveQuery,
  useArticlesUpdateMutation,
  useArticlesPartialUpdateMutation,
  useArticlesDestroyMutation,
  useArticlesAmplifyRetrieveQuery,
  useArticlesAuthorsListQuery,
  useArticlesAuthorsCreateMutation,
  useArticlesAuthorsRetrieveQuery,
  useArticlesAuthorsUpdateMutation,
  useArticlesAuthorsPartialUpdateMutation,
  useArticlesAuthorsDestroyMutation,
  useArticlesFeedListQuery,
  useArticlesFeedCreateMutation,
  useArticlesFeedRetrieveQuery,
  useArticlesFeedUpdateMutation,
  useArticlesFeedPartialUpdateMutation,
  useArticlesFeedDestroyMutation,
  useArticlesFeedArticlesListQuery,
  useArticlesFeedKeywordsListQuery,
  useArticlesFeedKeywordsCreateMutation,
  useArticlesFeedKeywordsRetrieveQuery,
  useArticlesFeedKeywordsUpdateMutation,
  useArticlesFeedKeywordsPartialUpdateMutation,
  useArticlesFeedKeywordsDestroyMutation,
  useArticlesFeedScoresListQuery,
  useArticlesFeedScoresCreateMutation,
  useArticlesFeedScoresRetrieveQuery,
  useArticlesFeedScoresUpdateMutation,
  useArticlesFeedScoresPartialUpdateMutation,
  useArticlesFeedScoresDestroyMutation,
  useArticlesImagesListQuery,
  useArticlesImagesCreateMutation,
  useArticlesImagesRetrieveQuery,
  useArticlesImagesUpdateMutation,
  useArticlesImagesPartialUpdateMutation,
  useArticlesImagesDestroyMutation,
  useArticlesPublishersListQuery,
  useArticlesPublishersCreateMutation,
  useArticlesPublishersRetrieveQuery,
  useArticlesPublishersUpdateMutation,
  useArticlesPublishersPartialUpdateMutation,
  useArticlesPublishersDestroyMutation,
  useArticlesPublishersLogosRetrieveQuery,
  useArticlesPublishersLogosCreateMutation,
  useArticlesPublishersLogosDestroyMutation,
  useArticlesSearchRetrieveQuery,
} = injectedRtkApi;
