import { Button } from "@/components/ui/controls/button";
import type { NewsFeedDataType } from "@/data-access/news/newsFeeds";
import { useNavigate } from "react-router-dom";
import { defaultFilters } from "../shared/FilterDays";
import { formatLastUpdateDate } from "../shared/utils";
import FeedItem from "./FeedItem";

type feedItemItemProps = {
  feedItem: NewsFeedDataType;
  setFeedArticleRemoveModalOpen: (isOpen: boolean) => void;
  setFeedIdToDeleteFromNewsFeed: (feedId?: number) => void;
};

const feedItemItem = ({
  feedItem,
  setFeedArticleRemoveModalOpen,
  setFeedIdToDeleteFromNewsFeed,
}: feedItemItemProps) => {
  const navigate = useNavigate();

  const topArticleUpdate = feedItem.feedItems[0]?.articleLastUpdateDate;

  const handleFeedSelectionAndNavigation = (storyId: string) => {
    navigate(`/newsfeeds/${storyId}${defaultFilters()}`);
  };

  const createImageElement = (url: string) => {
    const image = new Image();
    image.src = url;
    return image;
  };

  const handleOpenArticleRemovalModal = () => {
    setFeedIdToDeleteFromNewsFeed(feedItem.id);
    setFeedArticleRemoveModalOpen(true);
  };

  return (
    <div
      className="relative focus-visible:outline-none w-[900px] h-auto mt-0 mx-auto mb-5 focus-visible:ring-2 focus-visible:ring-blue-750 bg-white shadow border rounded-md border-slate-100 flex items-center flex-col"
      onKeyUp={(event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === "Enter") {
          event.preventDefault();
          handleFeedSelectionAndNavigation(`${feedItem.id}`);
        }
      }}
    >
      <div className="bottom-0 flex w-full pl-8 pr-8 justify-between items-center">
        <h1 className="mt-4 text-3xl text-slate-800 font-serif">
          {feedItem.feedName}
        </h1>
        <Button
          variant="secondary"
          className="mt-4 bg-slate-100 text-black hover:bg-slate-200 hover:text-black"
          onClick={() => handleFeedSelectionAndNavigation(`${feedItem.id}`)}
        >
          View Newsfeed
        </Button>
      </div>
      <div className="bottom-0 flex w-full pl-8 pr-8 justify-between items-center">
        <h2 className="my-2 text-lg font-bold text-slate-800">
          Top Article
          {topArticleUpdate && (
            <span className="text-slate-300 text-xs ml-2 font-extralight">
              {formatLastUpdateDate(topArticleUpdate)}
            </span>
          )}
        </h2>
      </div>
      <div className="p-4 h-full">
        <FeedItem
          image={createImageElement(feedItem.feedItems[0]?.imageUrl || "")}
          isNewsFeed={true}
          parentFeedId={feedItem.id}
          {...feedItem.feedItems[0]}
          prominence={feedItem.feedItems[0]?.prominence ?? []}
          setFeedArticleRemoveModalOpen={handleOpenArticleRemovalModal}
        />
      </div>
    </div>
  );
};

export default feedItemItem;
