import { ReactComponent as AngryEmoji } from "@/assets/svg/Angry_emoji.svg";
import { ReactComponent as Buzzsumo } from "@/assets/svg/Buzzsumo.svg";
import { ReactComponent as FacebookIcon } from "@/assets/svg/Facebook_icon.svg";
import { ReactComponent as HahaEmoji } from "@/assets/svg/Haha_emoji.svg";
import { ReactComponent as LoveEmoji } from "@/assets/svg/Love_emoji.svg";
import { ReactComponent as PinterestIcon } from "@/assets/svg/Pinterest icon.svg";
import { ReactComponent as RedditIcon } from "@/assets/svg/Reddit_icon.svg";
import { ReactComponent as SadEmoji } from "@/assets/svg/Sad_emoji.svg";
import { ReactComponent as WowEmoji } from "@/assets/svg/Wow_emoji.svg";
import { Button } from "@/components/ui/button";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/data-display/tooltip";
import { graphql } from "@/data-access/graphql";
import {
  ArrowFatUp,
  Chat,
  ShareFat,
  ThumbsUp,
  UploadSimple,
  XLogo,
} from "@phosphor-icons/react";
import * as TooltipPrimitive from "@radix-ui/react-tooltip";

import { Skeleton } from "@/components/ui/feedback/skeleton";
import { useEffect, useState } from "react";
import { useQuery } from "urql";
import { formatNumber } from "./utils";

const ArticleSocialQuery = graphql(`
  query ArticleSocial($articleId: ID!) {
    articleSocial(filters: { article: { id: { exact: $articleId } } }) {
      facebookComments
      facebookReactions
      facebookShares
      facebookLikes
      redditEngagements
      twitterShares
      hahaCount
      loveCount
      sadCount
      wowCount
      angryCount
      pinterestShares
    }
  }
`);

interface BuzzsumoArticleProps {
  articleId?: number;
  score?: number | null;
}

interface SocialData {
  facebookComments: number | null;
  facebookReactions: number | null;
  facebookShares: number | null;
  facebookLikes: number | null;
  redditEngagements: number | null;
  twitterShares: number | null;
  hahaCount: number | null;
  loveCount: number | null;
  sadCount: number | null;
  wowCount: number | null;
  angryCount: number | null;
  pinterestShares: number | null;
}

interface SocialDataItemProps {
  icon: React.ElementType;
  label: string;
  value: number | null;
  loading: boolean;
}

const SocialDataItem: React.FC<SocialDataItemProps> = ({
  icon: Icon,
  label,
  value,
  loading,
}) => (
  <div className="flex justify-between items-center text-xs text-gray-500 mt-1 pl-5">
    <div className="flex items-center space-x-2">
      <Icon className="w-4 h-4" />
      <span>{label}:</span>
    </div>
    <p className="font-bold text-gray-500">
      {loading ? (
        <Skeleton className="h-3 w-5 bg-gray-500/25" />
      ) : value !== null ? (
        formatNumber(value)
      ) : (
        0
      )}
    </p>
  </div>
);

interface SocialSectionProps {
  title: string;
  icon: React.ElementType;
  items: Array<{
    label: string;
    value?: number | null;
    icon: React.ElementType;
  }>;
  loading: boolean;
}

const SocialSection: React.FC<SocialSectionProps> = ({
  title,
  icon: SectionIcon,
  items,
  loading,
}) => (
  <div className="mb-2">
    <h3 className="font-normal flex items-center space-x-2 text-xs text-gray-500">
      <div className="flex items-center space-x-2">
        <SectionIcon className="w-4 h-4" />
        <span>{title}</span>
      </div>
    </h3>
    {items.map(({ label, value, icon }) => (
      <SocialDataItem
        key={label}
        icon={icon}
        label={label}
        value={value ?? 0}
        loading={loading}
      />
    ))}
  </div>
);

interface SocialDataDisplayProps {
  score?: number | null;
  onHoverChange: (isHovering: boolean) => void;
  loading: boolean;
  socialData: SocialData | null;
}

const SocialDataDisplay: React.FC<SocialDataDisplayProps> = ({
  score,
  onHoverChange,
  loading,
  socialData,
}) => (
  <TooltipProvider>
    <div className="inline-flex space-x-1 items-center text-gray-500 text-xs">
      <Tooltip>
        <TooltipTrigger className="inline-flex space-x-1 items-center text-gray-500 text-xs">
          <Buzzsumo className="w-4 h-4 rounded-full" />
        </TooltipTrigger>
        <TooltipContent className="max-w-80 text-xs">
          Social engagements for Facebook, Twitter/X, and Pinterest
        </TooltipContent>
      </Tooltip>
      {score !== undefined && score !== null ? (
        <Tooltip>
          <TooltipTrigger>
            <Button
              variant="ghost"
              size="none"
              onHoverChange={onHoverChange}
              className="inline-flex space-x-1 items-center text-gray-500 text-xs"
            >
              <span>Social: </span>
              <p className="font-bold text-gray-500">{formatNumber(score)}</p>
            </Button>
          </TooltipTrigger>
          <TooltipPrimitive.Portal>
            <TooltipContent className="p-4 w-64 bg-white shadow-md rounded-lg z-50 relative overflow-visible">
              <SocialSection
                title="Facebook engagement"
                icon={FacebookIcon}
                items={[
                  {
                    label: "Likes",
                    value:
                      socialData?.facebookLikes ||
                      socialData?.facebookReactions,
                    icon: ThumbsUp,
                  },
                  {
                    label: "Shares",
                    value: socialData?.facebookShares,
                    icon: ShareFat,
                  },
                  {
                    label: "Comments",
                    value: socialData?.facebookComments,
                    icon: Chat,
                  },
                ]}
                loading={loading}
              />
              <SocialSection
                title="Facebook reactions"
                icon={FacebookIcon}
                items={[
                  {
                    label: "Angry",
                    value: socialData?.angryCount,
                    icon: AngryEmoji,
                  },
                  {
                    label: "Sad",
                    value: socialData?.sadCount,
                    icon: SadEmoji,
                  },
                  {
                    label: "Haha",
                    value: socialData?.hahaCount,
                    icon: HahaEmoji,
                  },
                  {
                    label: "Wow",
                    value: socialData?.wowCount,
                    icon: WowEmoji,
                  },
                  {
                    label: "Love",
                    value: socialData?.loveCount,
                    icon: LoveEmoji,
                  },
                ]}
                loading={loading}
              />
              <SocialSection
                title="X (Twitter) engagement"
                icon={XLogo}
                items={[
                  {
                    label: "Shares",
                    value: socialData?.twitterShares,
                    icon: ShareFat,
                  },
                ]}
                loading={loading}
              />
              <SocialSection
                title="Reddit engagement"
                icon={RedditIcon}
                items={[
                  {
                    label: "Votes and comments",
                    value: socialData?.redditEngagements,
                    icon: ArrowFatUp,
                  },
                ]}
                loading={loading}
              />
              <SocialSection
                title="Pinterest engagement"
                icon={PinterestIcon}
                items={[
                  {
                    label: "Shares",
                    value: socialData?.pinterestShares,
                    icon: UploadSimple,
                  },
                ]}
                loading={loading}
              />
            </TooltipContent>
          </TooltipPrimitive.Portal>
        </Tooltip>
      ) : (
        <>
          <span>Social: </span>
          <p className="font-bold text-gray-500">N/A</p>
        </>
      )}
    </div>
  </TooltipProvider>
);

const BuzzsumoArticle: React.FC<BuzzsumoArticleProps> = ({
  articleId,
  score,
}) => {
  const [socialData, setSocialData] = useState<SocialData | null>(null);
  const [loading, setLoading] = useState(false);
  const [intendToOpen, setIntendToOpen] = useState(false);

  const [{ data }, reexecuteQuery] = useQuery<{ articleSocial: SocialData[] }>({
    query: ArticleSocialQuery,
    requestPolicy: "cache-and-network",
    variables: { articleId },
    pause: !intendToOpen,
  });

  useEffect(() => {
    const socialData = data?.articleSocial?.[0] ?? null;
    setSocialData(socialData);
    setLoading(false);

    // Cleanup function
    return () => {
      setSocialData(null);
      setLoading(true);
    };
  }, [data]);

  const onIntentChange = (isHovering: boolean) => {
    setIntendToOpen(isHovering);
    if (isHovering && !socialData) {
      setLoading(true);
      reexecuteQuery();
    }
  };

  return (
    <SocialDataDisplay
      score={score}
      onHoverChange={onIntentChange}
      loading={loading}
      socialData={socialData}
    />
  );
};

export default BuzzsumoArticle;
