import { FeedContentLayout } from "@/components/layouts/FeedContentLayout";
import NewsEmptyState from "@/components/news/NewsEmptyState";
import FeedArticleRemove from "@/components/news/article/FeedArticleRemove.tsx";
import FeedHeader from "@/components/news/feed/FeedHeader";
import NewsFeedItem from "@/components/news/feed/NewsFeedItem";
import FeedSheets from "@/components/news/shared/FeedSheets";
import { NewsFeedsDataQuery } from "@/data-access/news/newsFeeds";
import type { NewsFeedDataType } from "@/data-access/news/newsFeeds";
import { useFeature } from "@/lib/feature-management";
import { CustomLoader } from "@/shared/CustomLoader";
import useArticleDeletionStore from "@/store/articleDeleteSlice.ts";
import useFeedStore from "@/store/useFeedStore";
import { useEffect, useState } from "react";
import { useQuery } from "urql";

const NewsFeedsPage = () => {
  const { filterDays } = useFeedStore((state) => ({
    filterDays: state.filterDays,
  }));
  const [feeds, setFeeds] = useState<NewsFeedDataType[]>([]);

  const [
    isFeedArticleRemoveModalOpen,
    setFeedArticleRemoveModalOpen,
    closeActionBar,
    setFeedIdToDeleteFromNewsFeed,
  ] = useArticleDeletionStore((state) => [
    state.isDeleteModalOpen,
    state.setDeleteModalOpen,
    state.closeActionBar,
    state.setFeedIdToDeleteFromNewsFeed,
  ]);

  const [{ data, fetching: loading }] = useQuery({
    query: NewsFeedsDataQuery,
    requestPolicy: "cache-and-network",
    variables: { dayFilter: filterDays },
  });
  const orderNewsFeedByDate = useFeature("order-newsfeed-by-date", false);
  const sortFunction = (feedA: NewsFeedDataType, feedB: NewsFeedDataType) => {
    if (orderNewsFeedByDate) {
      // Should we get the actual date?
      const aDate = new Date(feedA.jobLastRun || new Date(0)).getTime();
      const bDate = new Date(feedB.jobLastRun || new Date(0)).getTime();
      return bDate - aDate;
    }
    return feedA.feedName.localeCompare(feedB.feedName);
  };

  const handleCloseModal = () => {
    setFeedArticleRemoveModalOpen(false);
    closeActionBar();
  };

  useEffect(() => {
    if (data?.newsfeedData && Array.isArray(data.newsfeedData)) {
      const feedsData = data.newsfeedData
        .filter(
          (feed: NewsFeedDataType) =>
            feed.feedItems != null && feed.feedItems.length > 0,
        )
        .sort((a: NewsFeedDataType, b: NewsFeedDataType) => sortFunction(a, b));
      setFeeds(feedsData);
    }
  }, [data]);

  return (
    <>
      <FeedArticleRemove
        isOpen={isFeedArticleRemoveModalOpen}
        onClose={handleCloseModal}
        numberOfItemsToDelete={1}
      />
      <FeedContentLayout>
        <FeedHeader />
        <main className="overflow-y-auto flex-col items-center p-4 pt-6 mb-4">
          {loading ? (
            <div className="flex justify-center items-center h-screen">
              <CustomLoader />
            </div>
          ) : feeds && feeds.length > 0 ? (
            feeds.map((item: NewsFeedDataType) => (
              <NewsFeedItem
                key={item?.id}
                feedItem={item}
                setFeedArticleRemoveModalOpen={setFeedArticleRemoveModalOpen}
                setFeedIdToDeleteFromNewsFeed={setFeedIdToDeleteFromNewsFeed}
              />
            ))
          ) : (
            <NewsEmptyState />
          )}
        </main>
      </FeedContentLayout>
      <FeedSheets />
    </>
  );
};

export default NewsFeedsPage;
