import CreateCampaignDialog from "@/components/campaigns/createCampaignDialog";
import { Sheet, SheetContent } from "@/components/ui/controls/sheet";
import { Dialog, DialogContent } from "@/components/ui/feedback/dialog";
import Checkbox from "@/components/ui/forms/checkbox";
import { CategoryQuery } from "@/data-access/news/categoryQuery";
import type { FeedWithAugTypes } from "@/data-access/news/newsFeeds";
import { useFeature } from "@/lib/feature-management";
import useArticleDeletionStore from "@/store/articleDeleteSlice.ts";
import useFeedStore from "@/store/useFeedStore";
import { WarningOctagon } from "@phosphor-icons/react";
import { memo, useCallback, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "urql";
import ArticleDetailPanel from "../article/ArticleDetailPanel";
import FeedArticle from "../article/FeedArticle";
import { defaultFilters } from "../shared/FilterDays";

export type FeedItemProps = {
  image?: HTMLImageElement;
  isNewsFeed?: boolean;
  parentFeedId?: number;
  setFeedArticleRemoveModalOpen: (isOpen: boolean) => void;
  // Add other properties here if needed
} & Partial<FeedWithAugTypes>;

const FeedItem = ({
  id,
  articleUrl,
  image,
  isNewsFeed = false,
  parentFeedId = 0,
  setFeedArticleRemoveModalOpen,
  ...rest
}: FeedItemProps) => {
  const amplifyEnabled = useFeature("article-amplify-enabled", false);
  const [{ data: categoryData }] = useQuery({ query: CategoryQuery });
  const [staleScore, updateArticle, deletedArticles] = useFeedStore((state) => [
    state.refreshingScore,
    state.updateArticle,
    state.removalArr,
  ]);
  const { feedId, storyId, articleId } = useParams();
  const navigate = useNavigate();
  const [amplifyModalOpen, setAmplifyModalOpen] = useState(false);
  const [articleIdsToDelete, setArticleIdToDelete] = useArticleDeletionStore(
    (state) => [state.articleIdsToDelete, state.setArticleIdToDelete],
  );

  const isArticleSelected = articleId === id?.toString();

  const updateNavUrl = useCallback(() => {
    if (isNewsFeed && parentFeedId) {
      navigate(`/newsfeeds/${parentFeedId}${defaultFilters()}`);
    } else {
      navigate(
        `/newsfeeds/${feedId}${storyId ? `/story/${storyId}` : ""}${
          !isArticleSelected ? `/articles/${id}` : ""
        }`,
      );
    }
  }, [feedId, storyId, isArticleSelected, navigate]);

  const handleDeleteClick = () => {
    setFeedArticleRemoveModalOpen(true);
    id && setArticleIdToDelete(id);
  };

  if (id && deletedArticles.includes(id)) return <></>;

  if (id === undefined) {
    return <></>;
  }

  return (
    <div className="flex items-center">
      {!!feedId && (
        <Checkbox
          className="mr-4"
          checked={articleIdsToDelete.includes(id)}
          onCheckedChange={() => setArticleIdToDelete(id)}
        />
      )}
      <FeedArticle
        categories={categoryData?.categories ?? []}
        handleClick={updateNavUrl}
        handleDeleteClick={handleDeleteClick}
        onChange={updateArticle}
        image={image}
        staleScore={staleScore}
        article={{ ...rest, id, articleUrl }}
      />
      <Sheet open={isArticleSelected}>
        <SheetContent
          className="w-[680px] sm:max-w-[680px]"
          hasClose={false}
          onInteractOutside={updateNavUrl}
        >
          <ArticleDetailPanel
            onDelete={() => setFeedArticleRemoveModalOpen(true)}
            {...rest}
            id={id}
            articleUrl={articleUrl}
          />
        </SheetContent>
      </Sheet>
      <Dialog open={amplifyModalOpen} onOpenChange={setAmplifyModalOpen}>
        {amplifyModalOpen && amplifyEnabled && (
          <CreateCampaignDialog url={articleUrl || ""} />
        )}
        {amplifyModalOpen && !amplifyEnabled && (
          <DialogContent className="min-w-[654px]">
            <div className="flex flex-row items-center gap-5">
              <WarningOctagon />
              <h3>Coming Soon</h3>
            </div>
          </DialogContent>
        )}
      </Dialog>
    </div>
  );
};

export default memo(FeedItem);
