import { Button } from "@/components/ui/button";
import FilterPill from "@/components/ui/data-display/filterPill";
import DateRangePicker, {
  type onApplyFn,
} from "@/components/ui/date-range-picker";
import { Skeleton } from "@/components/ui/feedback/skeleton";
import Checkbox from "@/components/ui/forms/checkbox";
import { Input, JollyTextField } from "@/components/ui/textfield";
import { cn } from "@/lib/utils";
import type { CategoryAppliedFilter } from "@/store/feedHelpers";
import useFeedStore, {
  useFeedName,
  useFeedPercent,
  useFilteredFeedItems,
} from "@/store/useFeedStore";
import { Funnel, MagnifyingGlass } from "@phosphor-icons/react";
import { useEffect, useRef, useState } from "react";
import { FeedExport } from "./FeedExport";
import { FeedSort } from "./FeedSort";

// @Utils
import { addCommasToNumbers, pluralize } from "@/lib/utils/prettyName";

interface FilterData {
  selectedOptions: string[];
  categoryKey: string;
}

export const FeedSearch = ({
  allArticleIds,
  articleIdsToDelete,
  deleteAllArticles,
  toggleDeleteAllArticles,
}: {
  allArticleIds: number[];
  articleIdsToDelete: number[];
  deleteAllArticles: boolean;
  toggleDeleteAllArticles(ids: number[]): void;
}) => {
  const [
    searchTerms,
    filterDays,
    isCustomDaysRange,
    startDate,
    endDate,
    isFiltered,
    fetchingFeed,
    filterBySearchTerms,
    updateCategoryAppliedFilters,
    updateDateRange,
    updateRelationship,
    categoryAppliedFilters,
  ] = useFeedStore((state) => [
    state.searchTerms,
    state.filterDays,
    state.isCustomDaysRange,
    state.startDate,
    state.endDate,
    state.isFiltered,
    state.fetchingFeed,
    state.filterBySearchTerms,
    state.updateCategoryAppliedFilters,
    state.updateDateRange,
    state.updateRelationship,
    state.categoryAppliedFilters,
  ]);

  const feedPercent = useFeedPercent();
  const feedName = useFeedName();
  const filteredFeedItems = useFilteredFeedItems();

  const [isFilteredUI, setIsFilteredUI] = useState<boolean>(isFiltered());
  const [isAdvancedSearchOpen, setIsAdvancedSearchOpen] = useState(false);

  useEffect(() => {
    setIsFilteredUI(isFiltered());
  }, [filteredFeedItems]);

  const debounceTimeout = useRef<NodeJS.Timeout | null>(null);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    // Only to restore the existing search terms
    setSearchTerm(searchTerms);
  }, []);

  useEffect(() => {
    if (debounceTimeout.current) clearTimeout(debounceTimeout.current);
    if (isAdvancedSearchOpen) return;

    debounceTimeout.current = setTimeout(() => {
      filterBySearchTerms(searchTerm);
    }, 500);

    return () => {
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }
    };
  }, [searchTerm]);

  const handleSubmitAdvancedSearch = () => {
    filterBySearchTerms(searchTerm);
  };

  const generateFilterPill = (
    { selectedOptions, categoryKey }: FilterData,
    categoryAppliedFilters: CategoryAppliedFilter,
    updateCategoryAppliedFilters: (filters: CategoryAppliedFilter) => void,
    updateRelationship: (key: string, relationship: "AND" | "OR") => void,
  ) => (
    <FilterPill
      key={categoryKey}
      title={categoryKey}
      relationship={categoryAppliedFilters[categoryKey]?.relationship ?? "AND"}
      selectedOptions={selectedOptions}
      setSelectedOptions={(options) => {
        if (options) {
          const newCategoryAppliedFilters = {
            ...categoryAppliedFilters,
            [categoryKey]: {
              ...categoryAppliedFilters[categoryKey],
              selected: options,
              relationship:
                categoryAppliedFilters[categoryKey]?.relationship ?? "AND",
              itemCounts: categoryAppliedFilters[categoryKey]?.itemCounts || {},
            },
          };
          updateCategoryAppliedFilters(newCategoryAppliedFilters);
        } else {
          updateCategoryAppliedFilters({
            ...categoryAppliedFilters,
            [categoryKey]: {
              ...categoryAppliedFilters[categoryKey],
              selected: [],
              relationship: "AND",
              itemCounts: categoryAppliedFilters[categoryKey]?.itemCounts || {},
            },
          });
        }
      }}
      advanced={true}
      setUpdateRelationships={(relationship) =>
        updateRelationship(categoryKey, relationship)
      }
    />
  );

  const filterData: FilterData[] = Object.entries(categoryAppliedFilters).map(
    ([key, value]) => {
      const options = Object.entries(value.itemCounts ?? {});
      return {
        options,
        selectedOptions: value.selected ?? [],
        categoryKey: key,
      };
    },
  );

  const onApply: onApplyFn = (range) => {
    updateDateRange(range.days, range.from, range.to, range.isCustom);
  };

  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      e.preventDefault();
      filterBySearchTerms(searchTerm);
    }
  };

  return (
    <>
      <div className="max-w-[956px] lg:mx-auto top-0 z-30 sticky">
        <div className="px-3 flex flex-row items-center gap-1 pt-4 bg-white">
          <Funnel className="h-4 w-4 mr-2 text-slate-500" />
          <div className="flex flex-row flex-wrap gap-2">
            {filterData.map((data) =>
              generateFilterPill(
                data,
                categoryAppliedFilters,
                updateCategoryAppliedFilters,
                updateRelationship,
              ),
            )}
          </div>
        </div>

        {isAdvancedSearchOpen && (
          <div className="px-4 py-4 bg-white">
            <JollyTextField
              textArea
              label="Advanced Search"
              value={searchTerm}
              onChange={(searchTerm) => setSearchTerm(searchTerm)}
              onKeyDown={handleKeyPress}
            />
            <div className="flex justify-end gap-2 mt-2">
              <Button
                type="button"
                variant="ghost"
                onPress={() => setIsAdvancedSearchOpen(false)}
              >
                Cancel
              </Button>
              <Button variant="outline" onPress={handleSubmitAdvancedSearch}>
                Submit
              </Button>
            </div>
          </div>
        )}
        <div className="flex px-3 pt-2 items-baseline justify-between bg-white">
          <div className="text-sm flex-grow text-slate-900/95 max-w-52 flex-1 leading-tight tracking-[0.14px]">
            <div className="text-sm mb-1">
              {fetchingFeed ? (
                <Skeleton className="w-32 h-5 bg-slate-300/50" />
              ) : (
                <div className={"flex items-center"}>
                  <Checkbox
                    checked={deleteAllArticles}
                    className={"mr-4"}
                    isSelectAll={true}
                    isPartialSelect={
                      !!articleIdsToDelete.length &&
                      articleIdsToDelete.length !== allArticleIds.length
                    }
                    onCheckedChange={() =>
                      toggleDeleteAllArticles(allArticleIds)
                    }
                  />
                  <div>
                    {`${addCommasToNumbers(
                      filteredFeedItems?.length,
                    )} Total ${pluralize(
                      filteredFeedItems?.length,
                      "article",
                    )} `}

                    {feedPercent !== undefined && feedPercent !== 100 ? (
                      <div className="text-[10px] tracking-[0.1px]">
                        {isFilteredUI &&
                          feedPercent !== undefined &&
                          !Number.isNaN(feedPercent) &&
                          feedPercent !== 0 && (
                            <span>
                              ({feedPercent}% of all {feedName} coverage)
                            </span>
                          )}
                        {isFilteredUI && feedPercent === 0 && (
                          <span>(Adjust filters to see more)</span>
                        )}
                      </div>
                    ) : null}
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="flex gap-1 items-end pb-1.5">
            {!isAdvancedSearchOpen && (
              <div className="relative flex items-center w-auto">
                <MagnifyingGlass
                  size={16}
                  className="text-gray-600 absolute left-2.5"
                />
                <Input
                  placeholder="Search for article"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  onKeyDown={(e) => handleKeyPress(e)}
                  className={cn(
                    "w-80 mr-1 h-9 py-2 pl-[2rem] pr-[4.5rem] items-end transition-width duration-200 ease-in-out",
                  )}
                />
                <Button
                  type="button"
                  variant="input"
                  size="input"
                  onPress={() => setIsAdvancedSearchOpen(!isAdvancedSearchOpen)}
                >
                  Expand
                </Button>
              </div>
            )}
            <DateRangePicker
              filterDays={filterDays}
              isCustomDaysRange={isCustomDaysRange}
              onApply={onApply}
              startDate={startDate}
              endDate={endDate}
            />
            <FeedSort />
            <FeedExport />
          </div>
        </div>
        <div className="z-10 bg-gradient-to-b from-white via-white/50 via-70% h-4 -mx-1 to-transparent" />
      </div>
    </>
  );
};
