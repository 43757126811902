/**
 * Base function to transform strings with consistent word splitting and casing
 */
const transformWords = (
  key: string,
  wordTransform: (word: string, index: number) => string,
  separator = " ",
): string => {
  // Split on both underscores and spaces, filter out empty strings
  return key
    .split(/[_\s]+/)
    .filter(Boolean)
    .map(wordTransform)
    .join(separator);
};

const capitalizeWord = (word: string): string =>
  word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();

export const getPrettyName = (key: string): string =>
  key.trim() ? transformWords(key, (word) => capitalizeWord(word)) : "";

export const getPascalCaseName = (key: string): string =>
  transformWords(key, capitalizeWord, "");

export const getAllCapsName = (key: string): string =>
  transformWords(key, (word) => word.toUpperCase());

export const toSnakeCase = (str: string): string =>
  str
    .trim()
    .split(/\s+/)
    .map((word) => word.toUpperCase())
    .join("_");

export const toLowerCase = (str: string): string => str.toLowerCase();

/**
 * Adds commas to numbers
 * @param {number} num
 * @returns {string}
 */
export const addCommasToNumbers = (num: number): string =>
  num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

/**
 * Returns the plural form of a word if the number is greater than 1.
 *
 * @param {number} num - The number to check
 * @param {string} word - The word to pluralize
 * @param {string} [pluralWord] - The optional plural form of the word
 * @returns {string} The pluralized word if num > 1, otherwise the original word
 */
export const pluralize = (
  num: number | undefined,
  word: string,
  pluralWord?: string,
): string => (num && num > 1 ? pluralWord || `${word}s` : word);
