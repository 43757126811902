import { isDevelopment } from "@/config";
import { type StateCreator, create } from "zustand";
import { devtools } from "zustand/middleware";

export type ArticleDeletionSlice = {
  articleIdsToDelete: number[];
  closeActionBar(): void;
  deleteAllArticles: boolean;
  setArticleIdToDelete: (id: number) => void;
  toggleDeleteAllArticles: (ids: number[]) => void;
  isDeleteModalOpen: boolean;
  setDeleteModalOpen: (isOpen: boolean, feedId?: number) => void;
  feedIdToDeleteFromNewsFeed: number | null;
  setFeedIdToDeleteFromNewsFeed(id?: number): void;
};

export const createArticleDeletionSlice: StateCreator<
  ArticleDeletionSlice,
  [["zustand/devtools", never]],
  []
> = (set) => ({
  articleIdsToDelete: [],
  deleteAllArticles: false,
  isDeleteModalOpen: false,
  feedIdToDeleteFromNewsFeed: null,
  setArticleIdToDelete: (id: number) =>
    set((state) => {
      const articleExists = state.articleIdsToDelete.includes(id);
      const articleIdsToDelete = articleExists
        ? state.articleIdsToDelete.filter((articleId) => articleId !== id)
        : [...state.articleIdsToDelete, id];
      return {
        articleIdsToDelete,
        deleteAllArticles: articleExists ? false : state.deleteAllArticles,
      };
    }),
  closeActionBar: () =>
    set(() => ({
      articleIdsToDelete: [],
      deleteAllArticles: false,
    })),
  toggleDeleteAllArticles: (allArticleIds: number[]) =>
    set((state) => {
      return {
        deleteAllArticles: !state.deleteAllArticles,
        articleIdsToDelete: !state.deleteAllArticles ? allArticleIds : [],
      };
    }),
  setDeleteModalOpen: (isOpen: boolean) =>
    set(() => ({
      isDeleteModalOpen: isOpen,
    })),
  setFeedIdToDeleteFromNewsFeed: (feedId?: number) => {
    set(() => ({
      feedIdToDeleteFromNewsFeed: feedId || null,
    }));
  },
});

const useArticleDeletionStore = create<ArticleDeletionSlice>()(
  devtools((...args) => ({ ...createArticleDeletionSlice(...args) }), {
    name: "articleDeletionStore",
    enabled: isDevelopment,
    store: "articleDeletion",
  }),
);

export default useArticleDeletionStore;
