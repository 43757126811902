import {
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogOverlay,
  DialogTitle,
} from "@/components/ui/dialog";
import { FieldError, Label } from "@/components/ui/field";
import ButtonGroup from "@/components/ui/molecules/ButtonGroup";
import { Input, TextField } from "@/components/ui/textfield";
import useFeedStore from "@/store/useFeedStore";
import { type ChangeEvent, useCallback, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

const MODAL_PARAM = "dialog" as const;

type Props = {
  open: boolean;
  onClose: () => void;
};

const AddArticleURLModal = (props: Props) => {
  const { open, onClose } = props;
  const [inputValue, setInputValue] = useState("");
  const [invalid, setInvalid] = useState(false);
  const addArticle = useFeedStore((state) => state.addArticle);
  const [_, setSearchParams] = useSearchParams();

  const handleInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const newValue = event.target.value;
      setInputValue(newValue);
    },
    [],
  );

  useEffect(() => {
    setInvalid(() => !validateUrl(inputValue));
  }, [inputValue]);

  const validateUrl = (value: string) => {
    const urlRegex =
      /^(https?:\/\/)?(www\.)?([a-zA-Z0-9]+(-[a-zA-Z0-9]+)*\.)+[a-zA-Z]{2,}(\/\S*)?$/;
    return urlRegex.test(value);
  };

  const onAddArticle = (closeFn: () => void) => {
    if (invalid) return;
    addArticle(inputValue);
    closeFn();
  };

  const handleCloseModal = () => {
    onClose();
    setInputValue("");
    setSearchParams(
      (prev) => {
        prev.delete(MODAL_PARAM);
        return prev;
      },
      { replace: true },
    );
  };

  return (
    <DialogOverlay
      isOpen={open}
      onOpenChange={(isOpen) => !isOpen && handleCloseModal()}
    >
      <DialogContent className="min-w-[425px] w-[25vw] max-w-[800px]">
        {({ close }) => (
          <>
            <DialogHeader>
              <DialogTitle>Add an article</DialogTitle>
            </DialogHeader>
            <div className="grid gap-4 py-4">
              <TextField
                autoFocus
                validate={(value) =>
                  validateUrl(value) ? null : "Please enter a valid URL"
                }
              >
                <Label>Article URL</Label>
                <Input
                  value={inputValue}
                  onChange={handleInputChange}
                  placeholder="https://example.com"
                />
                <FieldError />
              </TextField>
            </div>
            <DialogFooter className="flex w-full">
              <ButtonGroup
                primaryLabel="Add Article"
                primaryAction={() => onAddArticle(close)}
                secondaryLabel="Cancel"
                secondaryAction={handleCloseModal}
              />
            </DialogFooter>
          </>
        )}
      </DialogContent>
    </DialogOverlay>
  );
};

export default AddArticleURLModal;
