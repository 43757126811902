import GifLoader from "@/assets/images/loader.gif";

const BuilderLoader = () => {
  return (
    <div className="campaign-loader p-5 flex items-center justify-center">
      <div className="text-center">
        <div>
          <img
            src={GifLoader}
            alt="Loading..."
            width={200}
            className="mx-auto"
          />
        </div>
        <h4 className="text-slate-800 text-lg font-semibold mt-5">
          Magic happening…
        </h4>
        <p className="text-slate-500 text-sm">
          Sit tight while we build your first draft
        </p>
      </div>
    </div>
  );
};

export default BuilderLoader;
