import { useFeatureValue as useGrowthBookFeatureValue } from "@growthbook/growthbook-react";
import { loadDatadog } from "../analytics/datadog";
import type { AppFeatures } from "./generated-types/app-features";

/**
 * A custom hook for feature flag management.
 *
 * @param {K} featureFlag - The name of the feature flag.
 * @param {AppFeatures[K]} fallbackValue - The fallback value to use if the feature flag cannot be evaluated.
 *
 * @returns {AppFeatures[K]} - The value of the feature flag if it's set, otherwise the fallback.
 *
 * @example
 * const featureValue = useFeature('my-feature', false);
 *
 * @remarks
 * If you encounter any type errors, run `pnpm type-gen` to generate the latest types.
 */
const useFeature = <K extends keyof AppFeatures>(
  featureFlag: K,
  fallbackValue: AppFeatures[K],
) => {
  const result = useGrowthBookFeatureValue(featureFlag, fallbackValue);
  loadDatadog().then((datadogRum) =>
    datadogRum.addFeatureFlagEvaluation(featureFlag, result),
  );

  return result;
};

export default useFeature;
