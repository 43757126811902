import type { AuthorType } from "@/types/article";
import { User } from "@phosphor-icons/react";
import { useState } from "react";
import type React from "react";
import EditableField from "../shared/EditableField";

interface ArticleAuthorProps {
  articleAuthors?: AuthorType[];
  overriddenArticleAuthors?: AuthorType[];
  onAuthorChange?: (author: string | null) => void;
}

const ArticleAuthor: React.FC<ArticleAuthorProps> = ({
  articleAuthors,
  overriddenArticleAuthors,
  onAuthorChange,
}) => {
  const authors = overriddenArticleAuthors ?? articleAuthors ?? [];
  const authorNames = authors.map((author) => author.name).join(", ");
  const [editedAuthorName, setEditedAuthorName] = useState(authorNames);

  const handleAuthorSave = (editedAuthor: string) => {
    if (editedAuthor.trim().length < 1) {
      return false;
    }
    onAuthorChange?.(editedAuthor);
    setEditedAuthorName(editedAuthor);
    return true;
  };

  return (
    <div className="relative">
      <EditableField
        fieldName="Author"
        initialFieldValue={editedAuthorName}
        onSave={handleAuthorSave}
        styles="inline-flex items-start justify-start text-xs text-gray-800 pl-[30px] pr-8"
        LeftIcon={User}
      />
    </div>
  );
};

export default ArticleAuthor;
