import { Checkbox } from "@/components/ui/forms/checkbox";
import { Check, X } from "@phosphor-icons/react";
import React, { useState } from "react";

interface AddNewFilterProps {
  onConfirm: (newValue: string) => void;
  onCancel: () => void;
}

const AddNewFilter: React.FC<AddNewFilterProps> = ({ onConfirm, onCancel }) => {
  const [newTopic, setNewTopic] = useState("");

  return (
    <div className="flex items-center hover:bg-slate-300/10 rounded-md cursor-pointer px-2 py-1">
      <div className="flex items-center flex-grow space-x-2 relative">
        <Checkbox id="all" checked={false} onChange={() => {}} />
        <input
          type="text"
          className="form-control text-sm border rounded-md p-2 pr-5 border-slate-200 text-slate-700 overflow-hidden whitespace-nowrap overflow-ellipsis focus-visible:outline-none focus-visible:ring-1"
          placeholder="Add new topic..."
          value={newTopic}
          onChange={(e) => setNewTopic(e.target.value)}
        />
        <Check
          size={16}
          className="text-slate-600 absolute right-1 top-2 z-10 cursor-pointer"
          onClick={() => onConfirm(newTopic)}
        />
      </div>
      <div className="text-slate-600 text-sm min-w-[1em] text-right ml-1">
        <X onClick={onCancel} aria-hidden className="h-5 w-5" />
      </div>
    </div>
  );
};

export default AddNewFilter;
