import { toast } from "@/components/ui/toast";
import { isDevelopment } from "@/config";
import {
  createHeaders,
  standardAuthFetch,
} from "@/data-access/core/authenticatedFetch";
import { apiEndpointBaseUrl } from "@/data-access/core/constants";
import store from "@/store/store";
import { types } from "@/types/types";
import { type UserInfo, defaultUserInfo } from "@/types/user/userInfo";
import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";

export const accountReset = () => ({
  type: types.accountReset,
});

interface AuthSlice {
  user: UserInfo;
  status: "idle" | "loading" | "succeeded" | "failed";
  loggedIn: boolean;
  error: string | null;
  isEmployee: boolean;
  startLogin: (payload: { email: string; password: string }) => Promise<void>;
  logoutUser: () => Promise<void>;
  setUser: (user: UserInfo) => void;
  startGoogleLogin: (payload: {
    client_id: string;
    access_token: string;
  }) => Promise<void>;
  startResetPassword: (email: string) => Promise<void>;
  startRegister: (payload: {
    email: string;
    password1: string;
    password2: string;
  }) => Promise<void>;
  resetPasswordConfirm: (payload: {
    uid: string | null;
    token: string | null;
    new_password1: string;
    new_password2: string;
  }) => Promise<void>;
}

const errorMessages = {
  invalidCredentials:
    "The username or password you entered is incorrect. Please try again.",
  failedRegistration:
    "We encountered an issue while creating your account. Please try again later.",
  googleLoginFailed:
    "There was an issue logging in with Google. Please retry or use a different login method.",
  resetPasswordFailed: "An error occurred. Please try again later.",
  resetPassConfirmFailed: "An error occurred. Please try again later.",
};

const STORAGE_KEYS = [
  "access_token",
  "refresh_token",
  "recent-searches",
  "newsamp_user",
  "persist:root",
];

export const clearLocalStorage = () => {
  for (const key of STORAGE_KEYS) {
    window.localStorage.removeItem(key);
  }
};

const logAndUpdateStatus = (error: unknown) => {
  const message = error instanceof Error ? error.message : String(error);
  console.error(error);
  toast.error(message);
  return { status: "failed" as const, error: message };
};

const useAuth = create<AuthSlice>()(
  devtools(
    persist(
      (set, get) => ({
        user: JSON.parse(
          window.localStorage.getItem("newsamp_user") ||
            JSON.stringify(defaultUserInfo),
        ),
        isEmployee: get()?.user?.email?.endsWith("@handraise.com") ?? false,
        status: "idle",
        loggedIn: Boolean(window.localStorage.getItem("access_token")),
        error: null,
        setUser: (user: UserInfo) => set({ user }),
        // TODO: need to hook this up
        changePassword: async (
          new_password1: string,
          new_password2: string,
        ) => {
          const apiUrl = `${apiEndpointBaseUrl}/auth/password/change/`;
          const fetchOptions = {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ new_password1, new_password2 }),
          };

          try {
            await standardAuthFetch(apiUrl, fetchOptions);
            set({ status: "succeeded", error: null });
            toast.success("Password changed successfully");
          } catch (error) {
            set(logAndUpdateStatus(error));
          }
        },
        logoutUser: async () => {
          const apiUrl = `${apiEndpointBaseUrl}/auth/logout/`;
          const token = window.localStorage.getItem("access_token");

          try {
            if (!token) {
              console.error("No token found");
            } else {
              const headers = await createHeaders("POST", token);
              await fetch(apiUrl, { method: "POST", headers });
            }
          } catch (error) {
            console.error("Error when logging out:", error);
          } finally {
            clearLocalStorage();
            set({
              user: defaultUserInfo,
              loggedIn: false,
            });
            store.dispatch(accountReset());
          }
        },
        startLogin: async ({
          email,
          password,
        }: { email: string; password: string }) => {
          set({ status: "loading", error: null });
          try {
            const resp = await fetch(`${apiEndpointBaseUrl}/auth/login/`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({ email, password }),
            });
            const body = await resp.json();

            if (resp.ok) {
              if (body.access) {
                setLocalStorageItems(body);
                set({ user: body.user, status: "succeeded", loggedIn: true });
              } else {
                throw new Error(errorMessages.invalidCredentials);
              }
            } else {
              throw new Error(errorMessages.invalidCredentials);
            }
          } catch (err) {
            set(logAndUpdateStatus(err));
          }
        },

        startRegister: async ({
          email,
          password1,
          password2,
        }: { email: string; password1: string; password2: string }) => {
          set({ status: "loading" });
          try {
            const resp = await fetch(
              `${apiEndpointBaseUrl}/auth/registration/`,
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({ email, password1, password2 }),
              },
            );
            const body = await resp.json();
            if (resp.ok) {
              setLocalStorageItems(body);
              set({ user: body.user, status: "succeeded", loggedIn: true });
            } else {
              console.error(
                "Error Registering",
                body.email,
                body.non_field_errors,
              );
              throw new Error(errorMessages.failedRegistration);
            }
          } catch (err) {
            set(logAndUpdateStatus(err));
          }
        },

        startGoogleLogin: async ({
          client_id,
          access_token,
        }: { client_id: string; access_token: string }) => {
          set({ status: "loading" });
          try {
            const resp = await fetch(`${apiEndpointBaseUrl}/auth/gauth/`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({ client_id, access_token }),
            });
            const body = await resp.json();
            if (resp.ok) {
              setLocalStorageItems(body);
              set({ user: body.user, status: "succeeded", loggedIn: true });
            } else {
              console.error(body.non_field_errors);
              throw new Error(errorMessages.googleLoginFailed);
            }
          } catch (err) {
            set(logAndUpdateStatus(err));
          }
        },

        startResetPassword: async (email: string) => {
          set({ status: "loading" });
          try {
            const resp = await fetch(
              `${apiEndpointBaseUrl}/auth/password/reset/`,
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({ email }),
              },
            );
            const body = await resp.json();
            if (resp.ok) {
              toast.success(body.detail);
              set({ status: "succeeded", error: null });
            } else {
              throw new Error(errorMessages.resetPasswordFailed);
            }
          } catch (err) {
            set(logAndUpdateStatus(err));
          }
        },

        resetPasswordConfirm: async (payload: {
          uid: string | null;
          token: string | null;
          new_password1: string;
          new_password2: string;
        }) => {
          set({ status: "loading" });
          try {
            const resp = await fetch(
              `${apiEndpointBaseUrl}/auth/password/reset/confirm/`,
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(payload),
              },
            );
            const body = await resp.json();
            if (resp.ok) {
              toast.success(body.detail);
              set({ status: "succeeded", error: null });
            } else {
              throw new Error(errorMessages.resetPassConfirmFailed);
            }
          } catch (err) {
            set(logAndUpdateStatus(err));
          }
        },
      }),
      {
        name: "auth-storage", // unique name for your storage
        getStorage: () => localStorage, // or sessionStorage
      },
    ),
    {
      name: "authStore",
      enabled: isDevelopment,
      store: "auth",
    },
  ),
);

// biome-ignore lint/suspicious/noExplicitAny: <legacy code moved here and warning to provide better sentry tracking on current functionality>
const setLocalStorageItems = (body: any) => {
  if (!body) {
    console.error("Missing body in setLocalStorageItems:", body);
    return; // Stop if body is missing
  }

  if (body.access) {
    window.localStorage.setItem("access_token", body.access);
  } else {
    console.warn("Missing access token in setLocalStorageItems");
  }

  if (body.refresh) {
    window.localStorage.setItem("refresh_token", body.refresh);
  } else {
    console.warn("Missing refresh token in setLocalStorageItems");
  }

  if (body.user) {
    window.localStorage.setItem("newsamp_user", JSON.stringify(body.user));
  } else {
    console.warn("Missing user in setLocalStorageItems");
  }
};

export default useAuth;
