import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/data-display/tooltip";
import type { StoryDataType } from "@/data-access/storyData";

import { Question } from "@phosphor-icons/react";
import { format } from "date-fns";
import { useEffect, useState } from "react";

const StoryHeader = ({
  storyData,
}: {
  storyData?: StoryDataType;
}) => {
  const [publishedDates, setPublishedDates] = useState<string[] | null>(null);
  const [articlePublisherLogoURLs, setArticlePublisherLogoURLs] = useState<
    string[]
  >([]);

  useEffect(() => {
    if (!storyData?.storyItems?.length) return;

    const items: Date[] = storyData.storyItems
      .map(
        (storyItem) => new Date(storyItem.articleLastUpdateDate || new Date()),
      )
      .sort((aDate: Date, bDate: Date) =>
        aDate.getTime() > bDate.getTime() ? 1 : -1,
      );

    const firstDate = format(items[0] as Date, "MMM dd, yyyy");
    const lastDate = format(items[items.length - 1] as Date, "MMM dd, yyyy");
    setPublishedDates([firstDate, lastDate]);

    const publisherLogoUrls = Array.from(
      new Set(
        storyData.storyItems
          .filter((storyItem) => Boolean(storyItem?.articlePublisher?.logoUrl))
          .map((storyItem) => storyItem?.articlePublisher?.logoUrl || ""),
      ),
    );

    setArticlePublisherLogoURLs(publisherLogoUrls);
  }, [storyData]);

  return (
    <div className="flex flex-col gap-10 max-w-[642px] mx-auto mb-10 overflow-auto flex-grow">
      <div className="inline-flex items-center justify-center">
        <div className="h-[76px] pr-8 flex flex-col gap-2 justify-start items-start">
          <div className="inline-flex space-x-2 items-center">
            <div>Story score</div>
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger asChild>
                  <Question />
                </TooltipTrigger>
                <TooltipContent>
                  Story score is a score developed by Handraise to compare the
                  quality of stories relative to the newsfeed.
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </div>
          <div className="inline-flex flex-col items-center justify-center px-2 py-2 bg-blue-100 rounded w-11 aspect-square">
            <p className="text-xl font-semibold leading-7 text-blue-600">
              {storyData?.maxScore}
            </p>
          </div>
        </div>
        <div className="h-[76px] flex flex-col gap-2 justify-start items-start border-l border-slate-200 pl-8">
          <div className="inline-flex space-x-2 items-center">
            <div>Total Engagement</div>
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger asChild>
                  <Question />
                </TooltipTrigger>
                <TooltipContent>
                  Total Engagement is a measure of article shares, comments, and
                  reactions in social media.
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </div>
          <div className="inline-flex flex-col items-center justify-center px-2 py-2 h-11">
            <p className="text-xl font-semibold leading-7 text-slate-800">
              {storyData?.storyItems?.[0]?.maxSocial}
            </p>
          </div>
        </div>
      </div>

      <div className="inline-flex flex-col gap-10 bg-white">
        <div className="flex flex-col space-y-5">
          <h2 className="text-4xl text-center font-serif leading-tight text-slate-800">
            {storyData?.headline}
          </h2>
          {publishedDates?.length === 2 && (
            <div className="inline-flex space-x-3 justify-center leading-5">
              <p className="text-sm font-medium leading-tight text-slate-500">
                <span className="mr-2">First Published</span>
                <b> {publishedDates[0]} </b>
              </p>
              <div className="h-full w-[1px] bg-slate-200" />
              <p className="text-sm font-medium leading-tight text-slate-500">
                <span className="mr-2">Last Published</span>
                <b> {publishedDates[1]} </b>
              </p>
            </div>
          )}
        </div>

        <div className="inline-flex gap-3 mx-auto items-center justify-center flex-wrap">
          {articlePublisherLogoURLs.map((logoURL: string) => (
            <img
              key={logoURL}
              src={logoURL}
              alt="Publisher Logo"
              className="h-9 w-9"
            />
          ))}
        </div>
        <div className="flex flex-col space-y-3">
          {storyData?.summary && (
            <div className="mx-auto text-left flex flex-col gap-4 text-slate-900">
              <h3>Summary</h3>
              <p className="text-xs tracking-wide leading-tight line-clamp-3">
                {storyData.summary}
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default StoryHeader;
