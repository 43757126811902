import { FeedContentLayout } from "@/components/layouts/FeedContentLayout";
import FeedContent from "@/components/news/feed/FeedContent";
import FeedFilters from "@/components/news/feed/FeedFilters";
import FeedHeader from "@/components/news/feed/FeedHeader";
import FeedSheets from "@/components/news/shared/FeedSheets";
import useFeedStore, { useFilteredFeedItems } from "@/store/useFeedStore";
import { useParams } from "react-router-dom";

const FeedPage: React.FC = () => {
  const { feedId } = useParams();
  const searchTerms = useFeedStore((state) => state.searchTerms);
  const filteredFeedItems = useFilteredFeedItems();

  return (
    <>
      <FeedContentLayout>
        <FeedHeader />
        <main className="flex flex-row">
          {feedId && (
            <>
              <FeedFilters />
              <FeedContent
                feedId={feedId}
                searchTerms={searchTerms}
                feedItems={filteredFeedItems}
              />
            </>
          )}
        </main>
      </FeedContentLayout>
      <FeedSheets />
    </>
  );
};

export default FeedPage;
