import { Button } from "@/components/ui/button";
import { toast } from "@/components/ui/toast";
import type { ArticlePublisherType } from "@/data-access/news/newsFeeds";
import useFeedStore from "@/store/useFeedStore";
import { useState } from "react";
import EditableDate from "../shared/EditableDate";
import EditableField from "../shared/EditableField";
import { formattedDateToUTC } from "../shared/utils";

interface ArticleHeaderProps {
  articleId: number;
  headline: string;
  overriddenHeadline?: string;
  articlePublisher: ArticlePublisherType;
  lastUpdate?: string | null;
  articleUrl?: string | null;
  handleHeadlineClick?: () => void;
  onHeadlineChange?: (newHeadline: string) => void;
}

const ArticleHeader: React.FC<ArticleHeaderProps> = ({
  articleId,
  headline,
  overriddenHeadline,
  articlePublisher,
  lastUpdate,
  articleUrl,
  handleHeadlineClick,
  onHeadlineChange,
}) => {
  const [updateArticle] = useFeedStore((state) => [state.updateArticle]);
  const [editedHeadline, setEditedHeadline] = useState(
    overriddenHeadline ?? headline,
  );

  const handleArticleOpen = () => {
    if (!articleUrl) return;
    window.open(articleUrl, "_blank", "noopener,noreferrer");
  };

  const handleHeadlineSave = (updatedHeadline: string) => {
    if (updatedHeadline.trim().length < 1) {
      return false;
    }

    onHeadlineChange?.(updatedHeadline);
    setEditedHeadline(updatedHeadline);
    return true;
  };

  const handleOnSaveDate = (date: Date) => {
    const newDate = date.toISOString();
    if (newDate !== lastUpdate) {
      updateArticle({ articleId, datePublished: newDate });
      toast.success("Date updated successfully.");
    }
  };

  return (
    <div className="flex gap-y-2 flex-col relative -mt-1">
      <div>
        <EditableDate
          initialDate={formattedDateToUTC(lastUpdate)}
          onSave={handleOnSaveDate}
        />
        <EditableField
          fieldName="Headline"
          initialFieldValue={editedHeadline}
          onSave={handleHeadlineSave}
          maxLines={3}
          styles="text-xl text-left font-serif pr-5 overflow-hidden leading-tight text-slate-700 line-clamp-3 underline-offset-2"
          nonEditStyles="hover:underline hover:cursor"
          nonHoverClick={handleHeadlineClick}
        />
      </div>
      <Button
        variant={"link"}
        className="justify-start p-0 m-0 h-5 space-x-1"
        onPress={handleArticleOpen}
      >
        {articlePublisher?.logoUrl && (
          <img
            src={articlePublisher.logoUrl}
            alt={articlePublisher.name}
            className="h-4 object-contain"
          />
        )}
        <p
          className="text-xs leading-tight font-normal text-blue-700 underline decoration-blue-500 hover:text-blue-800 hover:decoration-blue-800 transition-colors overflow-hidden whitespace-nowrap text-ellipsis"
          title={articlePublisher?.name || "Unknown Publisher"}
        >
          {articlePublisher?.name || "Unknown Publisher"}
        </p>
      </Button>
    </div>
  );
};

export default ArticleHeader;
