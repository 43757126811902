import { Button } from "@/components/ui/button";
import { toast } from "@/components/ui/toast";
import type { FeedDataTermsType } from "@/data-access/news/newsFeeds";
import { CircleNotch, Plus } from "@phosphor-icons/react";
import { type ChangeEvent, useState } from "react";
import { SheetContent, SheetTitle } from "../ui/controls/sheet";
import { Label } from "../ui/field";
import { Input } from "../ui/textfield";
import KeyTerm from "./feed-settings/KeyTerm";
import Topic from "./feed-settings/Topic";

interface BaseNewsFeedSettingPanelProps {
  initialName: string;
  initialTerms: FeedDataTermsType[];
  initialTags: string[];
  isLoading: boolean;
  onSubmit: (data: {
    name: string;
    terms: FeedDataTermsType[];
    tags: string[];
  }) => void;
  title: string;
  submitButtonText: string;
  loadingButtonText: string;
}

const BaseNewsFeedSettingPanel = ({
  initialName,
  initialTerms,
  initialTags,
  isLoading,
  onSubmit,
  title,
  submitButtonText,
  loadingButtonText,
}: BaseNewsFeedSettingPanelProps) => {
  const [feedName, setFeedName] = useState<string>(initialName);
  const [keyTerms, setKeyTerms] = useState<FeedDataTermsType[]>(
    initialTerms.length
      ? initialTerms
      : [
          {
            term: "",
            description: "",
          },
        ],
  );
  const [topTopics, setTopTopics] = useState<string[]>(initialTags);
  const [newTopic, setNewTopic] = useState("");

  const changeKeyTerm = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    field: string,
    index: number,
  ) => {
    const newKeyTerms = keyTerms.map((term, i) =>
      i === index ? { ...term, [field]: e.target?.value } : term,
    );
    setKeyTerms(newKeyTerms);
  };

  const changeTopic = (e: ChangeEvent<HTMLInputElement>, index: number) => {
    setTopTopics((topTopics) =>
      topTopics.map((topic, i) => (i === index ? e.target?.value : topic)),
    );
  };

  const handleRemoveKeyTerm = (index: number) => {
    setKeyTerms((keyTerms) => keyTerms.filter((_, i) => i !== index));
  };

  const handleRemoveTopic = (index: number) => {
    setTopTopics((topTopics) => topTopics.filter((_, i) => i !== index));
  };

  const validateForm = () => {
    if (!feedName) {
      toast.error("Please enter a feed name");
      return false;
    }
    if (!keyTerms || !keyTerms.length) {
      toast.error("Please add at least one Company/Brand key term");
      return false;
    }
    if (keyTerms.some((term) => term.term.trim() === "")) {
      toast.error("Please enter a value for each Company/Brand key term");
      return false;
    }
    if (topTopics.some((topic) => topic.trim() === "")) {
      toast.error("Please enter a value for each top topic");
      return false;
    }

    if (keyTerms.some((term) => term.description.trim() === "")) {
      toast.error("Please ensure all terms have descriptions");
      return false;
    }

    return true;
  };

  const handleSubmit = () => {
    if (!validateForm()) return;
    const data = {
      name: feedName,
      terms: keyTerms.map((term) => ({
        term: term.term,
        description: term.description || "",
      })),
      tags: topTopics,
    };
    onSubmit(data);
  };

  const addTopic = () => {
    setTopTopics((topTopics) => [...topTopics, newTopic]);
    setNewTopic("");
  };

  const addKeyTerm = () => {
    const newKeyTerm: FeedDataTermsType = { term: "", description: "" };
    setKeyTerms([...keyTerms, newKeyTerm]);
  };

  return (
    <SheetContent hasClose={false} className="p-5 max-w-md pt-0">
      <SheetTitle className="sticky z-10 top-0 bg-white">
        <div className="flex flex-row justify-between items-center py-4">
          <h3>{title}</h3>
          <div className="flex gap-2">
            <Button
              variant="default"
              isDisabled={isLoading}
              onPress={handleSubmit}
            >
              {isLoading ? loadingButtonText : submitButtonText}
              {isLoading && (
                <CircleNotch
                  size={20}
                  className="ml-2 animate-spin"
                  weight="bold"
                />
              )}
            </Button>
          </div>
        </div>
        <div className="h-px w-full bg-slate-100" />
      </SheetTitle>

      <div className="flex flex-col w-full gap-7 pt-5">
        <div className="w-full">
          <Label htmlFor="newsfeed-name">Newsfeed Name</Label>
          <Input
            autoFocus
            className="w-full"
            id="newsfeed-name"
            value={feedName}
            onChange={(e) => setFeedName(e.target.value)}
            placeholder="Enter a name for your newsfeed"
            disabled={isLoading}
          />
        </div>
        <div className="w-full">
          <Label>Company/Brand key terms</Label>
          <p className="text-sm text-slate-500 mb-2">
            Enter key terms for news related to businesses, companies, or brands
            you want to see.
          </p>
          {keyTerms.map((term, index) => (
            <KeyTerm
              // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
              key={index}
              term={term}
              disabled={isLoading}
              onChange={(e, field) => changeKeyTerm(e, field, index)}
              onRemove={() => handleRemoveKeyTerm(index)}
              canRemove={!!index}
            />
          ))}

          <Button
            variant="ghost"
            onPress={addKeyTerm}
            className="text-violet-950"
            isDisabled={isLoading}
          >
            <Plus className="h-4 w-4 mr-2" />
            Add
          </Button>
        </div>
        <div className="w-full">
          <Label>Top Topics</Label>
          {topTopics.map((topic, index) => (
            <Topic
              // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
              key={index}
              disabled={isLoading}
              topic={topic}
              onChange={(e) => changeTopic(e, index)}
              onRemove={() => handleRemoveTopic(index)}
            />
          ))}
          <div className="flex gap-2 items-center">
            {/* <Input
              className="flex-grow"
              value={newTopic}
              onChange={(e) => setNewTopic(e.target.value)}
              placeholder="Enter topic associated with terms"
            /> */}
            <Button
              variant="ghost"
              onPress={addTopic}
              className="py-1 text-violet-950"
              isDisabled={isLoading}
            >
              <Plus className="h-4 w-4 mr-2" />
              Add
            </Button>
          </div>
        </div>
      </div>
    </SheetContent>
  );
};

export default BaseNewsFeedSettingPanel;
