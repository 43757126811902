import { isProduction } from "@/config";
import useAuth from "@/store/authSlice";
import { GrowthBook, GrowthBookProvider } from "@growthbook/growthbook-react";
import type React from "react";
import { useEffect } from "react";
import type { AppFeatures } from "./generated-types/app-features";

export const gb = new GrowthBook<AppFeatures>({
  apiHost: "https://cdn.growthbook.io",
  clientKey: import.meta.env.VITE_GROWTHBOOK_CLIENT_KEY,
  enableDevMode: !isProduction,
  trackingCallback: (experiment, result) => {
    if (typeof window?.gtag === "function") {
      window.gtag("event", "experiment_viewed", {
        event_category: "experiment",
        experiment_id: experiment.key,
        variation_id: result.variationId,
      });
    } else {
      isProduction && console.error("no gtag");
    }
  },
});
gb.init({
  streaming: true,
});

export const FeatureManagementProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const user = useAuth((state) => state.user);
  const loggedIn = useAuth((state) => state.loggedIn);
  const isEmployee = useAuth((state) => state.isEmployee);

  useEffect(() => {
    const attributes = {
      id: user?.id,
      loggedIn,
      email: user?.email,
      company: user?.default_tenant?.slug,
      employee: isEmployee,
    };

    gb.setAttributes(attributes);
  }, [user?.id, loggedIn, user?.email, user?.default_tenant?.slug, isEmployee]);

  return <GrowthBookProvider growthbook={gb}>{children}</GrowthBookProvider>;
};
